<template>
  <div class="main-cpv-resolve d-block w-100 fade-in-up mb-4">
    <b-jumbotron class="title-page mb-5 mt-5 title_page">
      <h1 class="text-center font-weight-bold">CPV Resolve</h1>
    </b-jumbotron>
    <bread-cpv class="adjustment-breadcrumb"></bread-cpv>

    <b-container>
      <header class="mb-4">
        <h5 class="text-center font-weight-bolder">
          Resoluções, gabaritos e comentários dos mais importantes vestibulares.
        </h5>
        <p class="text-center lead">
          Selecione abaixo o vestibular para ver as <span class="font_bold">correções disponíveis.</span>
        </p>
      </header>

      <b-row class="d-flex justify-content-center justify-content-md-start">
        <b-col cols="6" md="4" lg="3" xl="2" v-for="(resolvido, i) in list" :key="i" class="mt-4">
          <b-link class="resolve__link" :href="resolvido.link_url">
            <b-card class="card__resolve p-2" no-body>
              <b-card-img :src="resolvido.img" class="img-fluid" :img-alt="resolvido.img"></b-card-img>
              <b-card-text class="text-center text-uppercase mt-2 mb-2 title-resolve">
                {{ resolvido.name }}
              </b-card-text>
            </b-card>
          </b-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BreadCpv from "@/components/global/bread-cpv.vue";
export default {
  name: "cpv-resolve",
  metaInfo: { title: "CPV Resolve" },
  components: { BreadCpv },
  data() {
    return {
      list: [
        {
          id: 0,
          name: "Albert Einstein",
          alts: "ALBERT EINSTEIN",
          img: require("@/components/global/image/resolve/logo-alberteinstein.jpeg"),
          link_url: "/vestibular-resolvido-albert-einstein",
        },
        {
          id: 1,
          name: "Enem",
          alts: "ENEM",
          img: "https://media.cpv.com.br/wp-content/uploads/sites/8/2016/05/27134427/enem-logo1-150x150.jpg",
          link_url: "/vestibular-resolvido-enem",
        },
        {
          id: 2,
          name: "ESPM",
          alts: "FACULDADE ESPM",
          img: require("@/assets/image/resolve/espm1-150x150.png"),
          link_url: "/vestibular-resolvido-espm",
        },
        {
          id: 3,
          name: "FGV Adm",
          alts: "FGV ADMINISTRAÇÃO",
          img: require("@/assets/image/resolve/fgv-admin2.png"),
          link_url: "/vestibular-resolvido-fgv-adm",
        },
        {
          id: 4,
          name: "FGV Direito",
          alts: "FGV DIREITO",
          img: require("@/assets/image/resolve/fgv-dir2-150x150.png"),
          link_url: "/vestibular-resolvido-fgv-direito",
        },
        {
          id: 5,
          name: "FGV Economia",
          alts: "FGV ECONOMIA",
          img: require("@/assets/image/resolve/fgv-eco2-150x150.png"),
          link_url: "/vestibular-resolvido-fgv-economia",
        },
        {
          id: 6,
          name: "Fuvest",
          alts: "FUVEST",
          img: require("@/assets/image/resolve/fuvest1-150x150.jpeg"),
          link_url: "/vestibular-resolvido-fuvest",
        },
        {
          id: 7,
          name: "Insper",
          alts: "FACULDADE INSPER",
          img: require("@/assets/image/resolve/insper1-150x150.jpeg"),
          link_url: "/vestibular-resolvido-insper",
        },
        {
          id: 8,
          name: "Mackenzie",
          alts: "FACULDADE MACKENZIE",
          img: require("@/assets/image/resolve/mack1-150x150.png"),
          link_url: "/vestibular-resolvido-mack",
        },
        {
          id: 9,
          name: "PUC Campinas",
          alts: "PUC CAMPINAS",
          img: require("@/assets/image/resolve/puc-campinas.jpeg"),
          link_url: "/vestibular-resolvido-puc-campinas",
        },
        {
          id: 10,
          name: "PUC São Paulo",
          alts: "PUC SÃO PAULO",
          img: require("@/assets/image/resolve/puc1-150x150.jpeg"),
          link_url: "/vestibular-resolvido-puc-sp",
        },
        {
          id: 11,
          name: "Unesp",
          alts: "UNESP",
          img: require("@/assets/image/resolve/unesp1-150x150.jpeg"),
          link_url: "/vestibular-resolvido-unesp",
        },
        {
          id: 12,
          name: "Unicamp",
          alts: "UNICAMP",
          img: require("@/assets/image/resolve/unicamp2-150x150.png"),
          link_url: "/vestibular-resolvido-unicamp",
        },
        {
          id: 13,
          name: "Unifesp",
          alts: "UNIVERSIDADE FEDERAL DE SÃO PAULO",
          img: require("@/assets/image/resolve/unifesp1-150x150.jpeg"),
          link_url: "vestibular-resolvido-unifesp",
        },
        // {
        //     id: 3,
        //     name: "FGV RELAÇÕES INTERNACIONAIS",
        //     alts: 'FGV RELAÇÕES INTERNACIONAIS',
        //     img: require('@/assets/image/resolve/fgv-RI-150x150.png'),
        //     link_url: 'https://dicas.cpv.com.br/vestibular-fgv-adm-2023-1-semestre-resolvido'
        // },

        {
          id: 5,
          name: "FMABC",
          alts: "FMABC MEDICINA",
          img: require("@/components/global/image/resolve/fmabc_new_logo.png"),
          class: "fmabc",
          link_url: "/vestibular-resolvido-fmabc",
          img_logo: "fmabc",
        },
        // {
        //     id: 6,
        //     name: "FACULDADE SÃO CAMILO",
        //     alts: 'SÃO CAMILO',
        //     img: require('@/assets/image/resolve/logo-sao-camilo-150x150.png'),
        //     link_url: '/vestibular-resolvido-sao-camilo/'
        // },
        {
          id: 7,
          name: "SANTA CASA",
          alts: "Faculdade Santa Casa",
          img: require("@/assets/image/resolve/Logo_Faculdade_Santa_Casa_SP-p-150x150.png"),
          link_url: "/vestibular-resolvido-santa-casa",
        },
      ],
    };
  },
  created: function () {},
  beforeDestroy: function () {},
  methods: {
    //
  },
};
</script>

<style scoped>
.main-cpv-resolve {
  font-family: "Clan-Book Book", "FF Clan Pro", sans-serif;
}

.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.title_page {
  margin: -8px 0px 55px !important;
  background-repeat: repeat;
  background-position: center;
  background-color: rgb(239, 72, 87);
  background-image: url("@/assets/image/cpv-fundo-4.png");
  background-size: 30%;
  /* background-attachment: fixed; */
  object-fit: cover;
  border-radius: 0;
}

.title_page h1 {
  font-size: 51px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.resolve__link {
  text-decoration: none;
  color: #3a1c57;
  font-weight: 600;
  font-size: 0.84375rem;
  line-height: 1.16859375rem;
  letter-spacing: 0.05rem;
}

.resolve__link .card__resolve {
  border: 1px solid #e9e9e9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 8px;
}

.resolve__link .card__resolve:hover {
  margin-top: -8px;
  transition: all 0.35s ease-out;
  box-shadow: 0 0.125rem 0.85rem rgba(0, 0, 0, 0.095);
  /* z-index: 9999; */
}

.img_logo {
  width: 100%;
  height: 142px;
}
</style>
