import Vue from "vue";
import moment from "moment";
moment.locale("pt-Br");
import { cpf } from "cpf-cnpj-validator";

Vue.filter("formatShortDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY");
  }
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY - HH:mm:ss");
  }
});

Vue.filter("formatDatePersonal", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MM");
  }
});

Vue.filter("formatDate-day", function (value) {
  if (value) {
    return moment(String(value)).format("DD");
  }
});
Vue.filter("formatDate-month", function (value) {
  if (value) {
    return moment(String(value)).format("MMMM");
  }
});
Vue.filter("formatDate-year", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY");
  }
});
Vue.filter("formatDecimalNumber", function (value) {
  if (value) {
    const val = Number(value).toFixed(2).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return "0,00";
  }
});

Vue.filter("formatCpf", function (value) {
  if (value) {
    return cpf.format(value);
  } else {
    return null;
  }
});

Vue.filter("formatPhoneNumber", function (value) {
  if (!value) return "";

  // Remove tudo que não for número
  const phone = value.toString().replace(/\D/g, "");

  // Verifica se tem 11 dígitos
  if (phone.length === 11) {
    return phone.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
  }

  // Retorna o valor original caso não esteja correto
  return value;
});
