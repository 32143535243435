<template>
  <div class="main_course fade-in-up mb-5">
    <div v-if="!tabelapreco" style="height: 60vh">
      <loading message="Carregando formulário de matrícula"></loading>
    </div>
    <div v-else>
      <jumbotron-course :title="tabelapreco ? tabelapreco.nome_site : null" />
      <bread-cpv :title="tabelapreco ? tabelapreco.nome_site : null" :slug="slug" />

      <b-container>
        <b-jumbotron class="bg-white p-0 text-center" header>
          <template #header>
            <span style="color: #333; font-size: 25.6px" class="font-weight-bold"> Formulário de Matrícula </span>
          </template>
          <div class="text-left">
            <ol class="list-matricula">
              <li>Preencha todos os campos da Ficha Cadastral abaixo para iniciar sua Matrícula.</li>
              <li>
                Você receberá por e-mail o Contrato de Prestação de Serviços. Ele chegará em nome de CPV Educacional e
                terá assinatura digital por meio idôneo e 100% seguro.
              </li>
              <li>
                Após a assinatura do Contrato de Prestação de Serviços, pague a primeira parcela (PIX, cartão de crédito
                ou Boleto), a ser encaminhado também por e-mail pelo CPV em no máximo 3 (três) dias úteis após efetivada
                a matrícula online.
              </li>
              <li>O CPV não aceita pagamentos na Secretaria de suas Unidades.</li>
              <li>
                Responda ao e-mail enviado pelo CPV anexando os seguintes documentos:
                <ol class="pl-4">
                  <li>Foto recente (que você goste muito, pois será usada no banner de aprovados).</li>
                  <li>Cópia do comprovante de residência do responsável financeiro.</li>
                  <li>Cópia do RG e do CPF do responsável financeiro.</li>
                  <li>Cópia do RG e do CPF do aluno.</li>
                </ol>
              </li>
              <li>
                Em caso de dúvidas, entre em contato através do
                <a href="https://api.whatsapp.com/send?1=pt_BR&amp;phone=551137402110">WhatsApp nesse link </a>
              </li>
            </ol>
          </div>
        </b-jumbotron>

        <hr />

        <b-form @submit.prevent="handleSubmit">
          <b-row class="mt-3">
            <b-col>
              <b-card
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                class="callout-top-primary"
              >
                <template v-slot:header>
                  <h5 class="mb-0 required-field">Unidade</h5>
                </template>
                <b-form-group class="mb-0">
                  <b-form-radio-group
                    id="gerais_unidade"
                    style="scroll-margin-top: 50vh"
                    v-model="$v.matricula.gerais.unidade_id.$model"
                    :options="optUnidades"
                    name="radio-unidades"
                    value-field="unidade_id"
                    text-field="unidade_nome"
                  ></b-form-radio-group>
                  <b-form-invalid-feedback
                    :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.unidade_id.$invalid"
                  >
                    <p v-if="$v.matricula.gerais.unidade_id.required == false">Campo obrigatório</p>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-card>
            </b-col>
            <b-col v-if="1 == 0">
              <b-card
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                class="callout-top-primary"
              >
                <template v-slot:header>
                  <h5 class="mb-0 required-field">Plano</h5>
                </template>
                <b-row v-if="!matricula.gerais.unidade_id">
                  <b-col class="text-center">
                    <span>Selecione uma unidade para escolher um plano</span>
                  </b-col>
                </b-row>
                <b-form-group class="mb-0" v-if="matricula.gerais.unidade_id">
                  <b-form-radio-group
                    id="gerais_plano"
                    style="scroll-margin-top: 50vh"
                    v-model="matricula.gerais.turma_oferta_id"
                    :options="filteredOfertas"
                    name="radio-planos"
                    value-field="turma_oferta_id"
                    text-field="texto_parcelas"
                    stacked
                  ></b-form-radio-group>
                  <!-- <b-form-invalid-feedback
                    :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.unidade_id.$invalid"
                  >
                    <p v-if="$v.matricula.gerais.unidade_id.required == false">Campo obrigatório</p>
                  </b-form-invalid-feedback> -->
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <!-- v-if="tabelapreco.grupo_curso.cursos.length !== 0" -->
          <b-row class="mt-3">
            <b-col>
              <b-card
                class="callout-top-primary"
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
              >
                <template v-slot:header>
                  <h5 class="mb-0">Dados do Aluno</h5>
                </template>

                <b-row>
                  <b-col cols="12" sm="6">
                    <b-form-group
                      label="Nome Completo"
                      label-for="aluno_nome"
                      label-class="font-weight-bold required-field"
                    >
                      <b-form-input
                        type="text"
                        style="scroll-margin-top: 50vh"
                        v-model.trim="$v.matricula.aluno.nome.$model"
                        id="aluno_nome"
                        name="aluno_nome"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.nome.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.nome.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.nome.alphaNum == false">
                          NOME do Aluno deve ser alfanumérico<br />
                          <span v-html="validateTextAlphaNum($v.matricula.aluno.nome.$model)"></span>
                        </p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <b-form-group label="E-mail" label-for="aluno_email" label-class="font-weight-bold required-field">
                      <b-form-input
                        type="text"
                        style="scroll-margin-top: 50vh"
                        v-model.trim="$v.matricula.aluno.email.$model"
                        id="aluno_email"
                        name="aluno_email"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.email.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.email.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.email.email == false">E-mail inválido</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="4" class="text-center">
                    <b-form-group label="Estrangeiro?" label-for="estrangeiro_aluno" label-class="font-weight-bold">
                      <b-form-checkbox
                        v-model="matricula.aluno.estrangeiro"
                        id="estrangeiro_aluno"
                        name="estrangeiro_aluno"
                        :value="1"
                        :unchecked-value="0"
                        size="lg"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4" v-show="matricula.aluno.estrangeiro">
                    <b-form-group label="RNE" label-for="aluno_rne" label-class="font-weight-bold required-field">
                      <b-form-input
                        id="aluno_rne"
                        style="scroll-margin-top: 50vh"
                        v-model="$v.matricula.aluno.rne.$model"
                        type="text"
                      />
                      <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.rne.$invalid">
                        <p v-if="$v.matricula.aluno.rne.required == false">Campo obrigatório</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4" v-show="!matricula.aluno.estrangeiro">
                    <b-form-group label="CPF" label-for="aluno_cpf" label-class="font-weight-bold required-field">
                      <the-mask
                        id="aluno_cpf"
                        style="scroll-margin-top: 50vh"
                        v-model="$v.matricula.aluno.cpf.$model"
                        :mask="'###.###.###-##'"
                        type="text"
                        debounce="1000"
                        placeholder="###.###.###-##"
                        class="form-control"
                      />
                      <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cpf.$invalid">
                        <p v-if="$v.matricula.aluno.cpf.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.cpf.cpfSize == false">O cpf deve conter 11 números</p>
                        <p v-if="$v.matricula.aluno.cpf.cpfValid == false">CPF Inválido</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="RG"
                      label-for="aluno_rg"
                      :label-class="
                        matricula.aluno.estrangeiro ? 'font-weight-bold' : 'font-weight-bold required-field'
                      "
                    >
                      <b-form-input
                        type="text"
                        style="scroll-margin-top: 50vh"
                        v-model.trim="$v.matricula.aluno.rg.$model"
                        name="aluno_rg"
                        id="aluno_rg"
                      />
                      <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.rg.$invalid">
                        <p v-if="$v.matricula.aluno.rg.required == false">Campo obrigatório</p>
                        <!-- <p v-if="$v.matricula.aluno.rg.rgValid == false">RG inválido</p> -->
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="Celular"
                      label-for="aluno_celular"
                      label-class="font-weight-bold required-field"
                    >
                      <the-mask
                        id="aluno_celular"
                        name="aluno_celular"
                        style="scroll-margin-top: 50vh"
                        v-model.trim="$v.matricula.aluno.celular.$model"
                        :mask="['(##) #####-####']"
                        type="text"
                        placeholder="(##) #####-####"
                        class="form-control"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.celular.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.celular.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.celular.celularSize == false">Celular inválido</p>
                        <p v-if="$v.matricula.aluno.celular.numeric == false">Campo deve ser numérico</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="Data de Nascimento"
                      label-for="aluno_nascimento"
                      label-class="font-weight-bold required-field"
                    >
                      <date-picker
                        id="aluno_nascimento"
                        name="aluno_nascimento"
                        style="scroll-margin-top: 50vh"
                        v-model.trim="$v.matricula.aluno.nascimento.$model"
                        lang="pt-br"
                        type="date"
                        value-type="DD-MM-YYYY"
                        format="DD-MM-YYYY"
                        input-class="cls-datepicker"
                        :editable="true"
                        :open.sync="openDatePickerAluno"
                      >
                        <template v-slot:input>
                          <masked-input
                            type="text"
                            name="aluno_nascimento_input"
                            class="cls-datepicker"
                            :value="$v.matricula.aluno.nascimento.$model"
                            :mask="dateMask"
                            :guide="false"
                            @change="handleChangeAluno"
                          >
                          </masked-input>
                        </template>
                        <template #footer="{ emit }">
                          <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                        </template>
                      </date-picker>

                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.nascimento.$invalid"
                      >
                        Campo obrigatório
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4">
                    <b-form-group label="Gênero" label-for="aluno_genero" label-class="font-weight-bold required-field">
                      <b-form-select
                        id="aluno_genero"
                        name="aluno_genero"
                        style="scroll-margin-top: 50vh"
                        v-model.trim="$v.matricula.aluno.genero.$model"
                      >
                        <option value="" disabled>Selecione o gênero</option>
                        <option v-for="gen in opt_genero" v-bind:key="gen.id" :value="gen.id">{{ gen.name }}</option>
                      </b-form-select>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.genero.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.genero.required == false">Campo obrigatório</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="divider">Endereço</div>
                <b-row>
                  <b-col cols="12" sm="3">
                    <b-form-group label="CEP" label-for="aluno_cep" label-class="font-weight-bold required-field">
                      <b-input-group>
                        <the-mask
                          id="aluno_cep"
                          name="aluno_cep"
                          style="scroll-margin-top: 50vh"
                          v-model.trim="$v.matricula.aluno.cep.$model"
                          :mask="'#####-###'"
                          type="text"
                          debounce="1000"
                          placeholder="#####-##"
                          class="form-control"
                        />

                        <template #append>
                          <b-button variant="outline-secondary" @click="buscaCepAluno()" :disabled="isLoadingCepAluno">
                            <i class="fa-solid fa-magnifying-glass"></i
                          ></b-button>
                        </template>
                      </b-input-group>
                      <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cep.$invalid">
                        <p v-if="$v.matricula.aluno.cep.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.cep.numeric == false">Campo deve ser numérico</p>
                        <p v-if="$v.matricula.aluno.cep.cepSize == false">Cep inválido</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="3">
                    <b-form-group label="Bairro" label-for="aluno_bairro" label-class="font-weight-bold required-field">
                      <b-input-group>
                        <b-form-input
                          v-model.trim="$v.matricula.aluno.bairro.$model"
                          style="scroll-margin-top: 50vh"
                          type="text"
                          id="aluno_bairro"
                          name="aluno_bairro"
                        />
                        <template #append>
                          <b-input-group-text v-if="isLoadingCepAluno">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </b-input-group-text>
                        </template>
                      </b-input-group>

                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.bairro.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.bairro.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.bairro.alphaNum == false">
                          Campo deve ser alfanumérico<br />
                          <span v-html="validateTextAlphaNum($v.matricula.aluno.bairro.$model)"></span>
                        </p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4">
                    <b-form-group label="Cidade" label-for="aluno_cidade" label-class="font-weight-bold required-field">
                      <b-input-group>
                        <b-form-input
                          v-model.trim="$v.matricula.aluno.cidade.$model"
                          style="scroll-margin-top: 50vh"
                          type="text"
                          id="aluno_cidade"
                          name="aluno_cidade"
                        />
                        <template #append>
                          <b-input-group-text v-if="isLoadingCepAluno">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cidade.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.cidade.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.cidade.alphaNum == false">
                          Campo deve ser alfanumérico<br />
                          <span v-html="validateTextAlphaNum($v.matricula.aluno.cidade.$model)"></span>
                        </p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="2">
                    <b-form-group label="UF" label-for="aluno_estado" label-class="font-weight-bold required-field">
                      <b-input-group>
                        <b-form-input
                          v-model.trim="$v.matricula.aluno.estado.$model"
                          style="scroll-margin-top: 50vh"
                          type="text"
                          id="aluno_estado"
                          name="aluno_estado"
                        />
                        <template #append>
                          <b-input-group-text v-if="isLoadingCepAluno">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.estado.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.estado.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.estado.alpha == false">
                          Campo deve ser alfabético<br />
                          <span v-html="validateTextAlpha($v.matricula.aluno.estado.$model)"></span>
                        </p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="6">
                    <b-form-group
                      label="Endereço"
                      label-for="aluno_endereco"
                      label-class="font-weight-bold required-field"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model.trim="$v.matricula.aluno.endereco.$model"
                          style="scroll-margin-top: 50vh"
                          type="text"
                          id="aluno_endereco"
                          name="aluno_endereco"
                        />
                        <template #append>
                          <b-input-group-text v-if="isLoadingCepAluno">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.endereco.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.endereco.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.endereco.alphaNum == false">
                          Campo deve ser alfanumérico<br />
                          <span v-html="validateTextAlphaNum($v.matricula.aluno.endereco.$model)"></span>
                        </p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="2">
                    <b-form-group label="Número" label-for="aluno_numero" label-class="font-weight-bold required-field">
                      <b-form-input
                        v-model.trim="$v.matricula.aluno.numero.$model"
                        style="scroll-margin-top: 50vh"
                        type="text"
                        id="aluno_numero"
                        name="aluno_numero"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.numero.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.numero.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.numero.alphaNum == false">
                          Campo deve ser alfanumérico<br />
                          <span v-html="validateTextAlphaNum($v.matricula.aluno.numero.$model)"></span>
                        </p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="4">
                    <b-form-group label="Complemento" label-for="aluno_complemento" label-class="font-weight-bold">
                      <b-form-input
                        v-model.trim="$v.matricula.aluno.complemento.$model"
                        style="scroll-margin-top: 50vh"
                        type="text"
                        id="aluno_complemento"
                        name="aluno_complemento"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.complemento.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.complemento.alphaNum == false">
                          Campo deve ser alfanumérico<br />
                          <span v-html="validateTextAlphaNum($v.matricula.aluno.complemento.$model)"></span>
                        </p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-card
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                class="callout-top-primary"
              >
                <template v-slot:header>
                  <h5 class="mb-0">Dados Acadêmicos</h5>
                </template>
                <b-row>
                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="Escola de Orígem"
                      label-for="aluno_escola"
                      label-class="font-weight-bold required-field"
                    >
                      <b-form-input
                        type="text"
                        v-model.trim="$v.matricula.aluno.escola.$model"
                        style="scroll-margin-top: 50vh"
                        id="aluno_escola"
                        name="aluno_escola"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.escola.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.escola.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.aluno.escola.alphaNum == false">
                          Campo deve ser alfanumérico<br />
                          <span v-html="validateTextAlphaNum($v.matricula.aluno.escola.$model)"></span>
                        </p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4" class="text-center">
                    <b-form-group
                      label="Concluiu o ensino médio?"
                      label-for="concluiu_ensino_medio"
                      label-class="font-weight-bold"
                    >
                      <b-form-checkbox
                        v-model="matricula.aluno.concluiu_ensino_medio"
                        id="concluiu_ensino_medio"
                        name="concluiu_ensino_medio"
                        :value="1"
                        :unchecked-value="0"
                        size="lg"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="Já fez cursinho? Qual?"
                      label-for="aluno_cursinho"
                      label-class="font-weight-bold"
                    >
                      <b-form-input
                        type="text"
                        v-model.trim="$v.matricula.aluno.cursinho.$model"
                        style="scroll-margin-top: 50vh"
                        id="aluno_cursinho"
                        name="aluno_cursinho"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cursinho.$invalid"
                      >
                        <p v-if="$v.matricula.aluno.cursinho.alphaNum == false">Campo deve ser alfanumérico</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-card
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                class="callout-top-primary"
                v-for="(question, index) in matricula.questions"
                v-bind:key="index"
              >
                <template v-slot:header>
                  <h5 class="mb-0" :class="question.required ? 'required-field' : null">{{ question.statement }}</h5>
                </template>
                <b-form-group class="mb-0">
                  <b-form-radio-group
                    style="scroll-margin-top: 50vh"
                    :id="`question_${question.id}`"
                    v-model="$v.matricula.questions.$each.$iter[index].value.$model"
                  >
                    <b-form-radio
                      :value="alt.id"
                      v-for="(alt, altIndex) in question.alternatives"
                      v-bind:key="altIndex"
                    >
                      {{ alt.id + " - " + alt.statement }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <b-form-invalid-feedback
                    :force-show="
                      question.required == 1 &&
                      submitStatus == 'ERROR' &&
                      $v.matricula.questions.$each.$iter[index].value.$invalid
                    "
                  >
                    <p v-if="$v.matricula.questions.$each.$iter[index].value.required == false">Campo obrigatório</p>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-card-group deck>
                <b-card
                  class="callout-top-primary"
                  header-bg-variant="transparent"
                  footer-bg-variant="transparent"
                  header-class="d-flex justify-content-between align-items-center"
                >
                  <template v-slot:header>
                    <h5 class="mb-0 required-field">Vestibulares aos quais irá concorrer</h5>
                  </template>

                  <b-row>
                    <b-col
                      cols="12"
                      sm="12"
                      md="6"
                      v-for="(itm, index) in matricula.faculdades"
                      :key="index"
                      class="mb-3 h-100"
                    >
                      <!-- :disabled="matricula.faculdades[index] != null" -->
                      <b-form-group class="mb-0">
                        <v-select
                          v-model="$v.matricula.faculdades.$each.$iter[index].$model"
                          :options="filteredFaculdades"
                          :clearable="true"
                          label="nome"
                          @input="inputFaculdade(index)"
                          placeholder="Selecione uma opção"
                        >
                          <template #no-options>
                            <em style="opacity: 0.5">Começe a digitar para buscar uma opção.</em>
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="d-center mb-1">
                              <span class="font-weight-bold">{{ option.nome }}</span>
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              <span>{{ option.nome }}</span>
                            </div>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback
                          :force-show="
                            index == 0 && submitStatus == 'ERROR' && $v.matricula.faculdades.$each.$iter[index].$invalid
                          "
                        >
                          <p v-if="$v.matricula.faculdades.$each.$iter[index].required == false">Campo obrigatório</p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>

                <b-card
                  class="callout-top-primary"
                  header-bg-variant="transparent"
                  footer-bg-variant="transparent"
                  header-class="d-flex justify-content-between align-items-center"
                >
                  <template v-slot:header>
                    <h5 class="mb-0 required-field">Carreiras que pretende seguir</h5>
                  </template>

                  <b-row>
                    <b-col
                      cols="12"
                      sm="12"
                      md="6"
                      v-for="(itm, index) in matricula.carreiras"
                      :key="index"
                      class="mb-3"
                    >
                      <b-form-group class="mb-0">
                        <v-select
                          v-model="$v.matricula.carreiras.$each.$iter[index].$model"
                          :options="filteredCarreiras"
                          :clearable="true"
                          label="nome"
                          @input="inputCarreira(index)"
                          placeholder="Selecione uma opção"
                        >
                          <template #no-options>
                            <em style="opacity: 0.5">Começe a digitar para buscar uma opção.</em>
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="d-center mb-1">
                              <span class="font-weight-bold">{{ option.nome }}</span>
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              <span>{{ option.nome }}</span>
                            </div>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback
                          :force-show="
                            index == 0 && submitStatus == 'ERROR' && $v.matricula.carreiras.$each.$iter[index].$invalid
                          "
                        >
                          <p v-if="$v.matricula.carreiras.$each.$iter[index].required == false">Campo obrigatório</p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-card-group>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <b-card-group deck>
                <b-card
                  class="callout-top-primary"
                  header-bg-variant="transparent"
                  footer-bg-variant="transparent"
                  header-class="d-flex justify-content-between align-items-center"
                >
                  <template v-slot:header>
                    <h5 class="mb-0 required-field">Como conheceu o CPV</h5>
                  </template>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <b-form-checkbox-group
                          v-model="$v.matricula.origins.$model"
                          :options="origins"
                          value-field="id"
                          text-field="name"
                          stacked
                        ></b-form-checkbox-group>
                        <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.origins.$invalid">
                          <p v-if="$v.matricula.origins.required == false">Campo obrigatório</p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>

                <b-card
                  class="callout-top-primary"
                  header-bg-variant="transparent"
                  footer-bg-variant="transparent"
                  header-class="d-flex justify-content-between align-items-center"
                >
                  <template v-slot:header>
                    <h5 class="mb-0 required-field">Por que escolheu CPV</h5>
                  </template>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <b-form-checkbox-group
                          v-model="$v.matricula.reasons.$model"
                          :options="reasons"
                          value-field="id"
                          text-field="name"
                          stacked
                        ></b-form-checkbox-group>
                        <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.reasons.$invalid">
                          <p v-if="$v.matricula.reasons.required == false">Campo obrigatório</p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-card-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-card
                class="callout-top-primary"
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                no-body
              >
                <template v-slot:header>
                  <h5 class="mb-0">Dados do Pai</h5>
                  <!-- v-model="matricula.aluno.estrangeiro" -->
                  <b-form-checkbox
                    id="nao_informar_pai"
                    name="nao_informar_pai"
                    v-model="matricula.pai.nao_informar"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Não informar
                  </b-form-checkbox>
                </template>
                <b-card-body class="position-relative">
                  <div v-if="matricula.pai.nao_informar" class="overlay"></div>
                  <div>
                    <b-row>
                      <b-col cols="12" sm="6">
                        <b-form-group
                          label="Nome Completo"
                          label-for="pai_nome"
                          label-class="font-weight-bold required-field"
                        >
                          <b-form-input
                            v-model.trim="$v.matricula.pai.nome.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="pai_nome"
                            name="pai_nome"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.nome.$invalid"
                          >
                            <p v-if="$v.matricula.pai.nome.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.nome.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="6">
                        <b-form-group
                          label="E-mail"
                          label-for="pai_email"
                          label-class="font-weight-bold required-field"
                        >
                          <b-form-input
                            v-model.trim="$v.matricula.pai.email.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="pai_email"
                            name="pai_email"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.email.$invalid"
                          >
                            <p v-if="$v.matricula.pai.email.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.email.email == false">E-mail inválido</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" sm="4" class="text-center">
                        <b-form-group label="Estrangeiro?" label-for="estrangeiro_pai" label-class="font-weight-bold">
                          <b-form-checkbox
                            v-model="matricula.pai.estrangeiro"
                            id="estrangeiro_pai"
                            name="estrangeiro_pai"
                            :value="1"
                            :unchecked-value="0"
                            size="lg"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4" v-show="matricula.pai.estrangeiro">
                        <b-form-group label="RNE" label-for="pai_rne" label-class="font-weight-bold required-field">
                          <b-form-input
                            style="scroll-margin-top: 50vh"
                            id="pai_rne"
                            v-model="$v.matricula.pai.rne.$model"
                            type="text"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.rne.$invalid"
                          >
                            <p v-if="$v.matricula.pai.rne.required == false">Campo obrigatório</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4" v-show="!matricula.pai.estrangeiro">
                        <b-form-group label="CPF" label-for="pai_cpf" label-class="font-weight-bold required-field">
                          <the-mask
                            id="pai_cpf"
                            v-model.trim="$v.matricula.pai.cpf.$model"
                            style="scroll-margin-top: 50vh"
                            :mask="'###.###.###-##'"
                            type="text"
                            debounce="1000"
                            placeholder="###.###.###-##"
                            class="form-control"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cpf.$invalid"
                          >
                            <p v-if="$v.matricula.pai.cpf.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.cpf.cpfSize == false">O cpf deve conter 11 números</p>
                            <p v-if="$v.matricula.pai.cpf.cpfValid == false">CPF Inválido</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="RG"
                          label-for="rg_pai"
                          :label-class="
                            matricula.pai.estrangeiro ? 'font-weight-bold' : 'font-weight-bold required-field'
                          "
                        >
                          <b-form-input
                            v-model.trim="$v.matricula.pai.rg.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            name="rg_pai"
                            id="rg_pai"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.rg.$invalid"
                          >
                            <p v-if="$v.matricula.pai.rg.required == false">Campo obrigatório</p>
                            <!-- <p v-if="$v.matricula.pai.rg.rgValid == false">RG inválido</p> -->
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="Celular"
                          label-for="pai_celular"
                          label-class="font-weight-bold required-field"
                        >
                          <the-mask
                            id="pai_celular"
                            name="pai_celular"
                            v-model.trim="$v.matricula.pai.celular.$model"
                            style="scroll-margin-top: 50vh"
                            :mask="['(##) #####-####']"
                            type="text"
                            placeholder="(##) #####-####"
                            class="form-control"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.celular.$invalid"
                          >
                            <p v-if="$v.matricula.pai.celular.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.celular.celularSize == false">Celular inválido</p>
                            <p v-if="$v.matricula.pai.celular.numeric == false">Campo deve ser numérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="Data de Nascimento"
                          label-for="pai_nascimento"
                          label-class="font-weight-bold required-field"
                        >
                          <date-picker
                            id="pai_nascimento"
                            name="pai_nascimento"
                            style="scroll-margin-top: 50vh"
                            v-model.trim="$v.matricula.pai.nascimento.$model"
                            lang="pt-br"
                            type="date"
                            value-type="DD-MM-YYYY"
                            format="DD-MM-YYYY"
                            input-class="cls-datepicker"
                            :editable="true"
                            :open.sync="openDatePickerPai"
                          >
                            <template v-slot:input>
                              <masked-input
                                type="text"
                                name="pai_nascimento_input"
                                class="cls-datepicker"
                                :value="$v.matricula.pai.nascimento.$model"
                                :mask="dateMask"
                                :guide="false"
                                @change="handleChangePai"
                              >
                              </masked-input>
                            </template>
                            <template #footer="{ emit }">
                              <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                            </template>
                          </date-picker>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.nascimento.$invalid"
                          >
                            Campo obrigatório
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group label="Gênero" label-for="pai_genero" label-class="font-weight-bold">
                          <b-form-select
                            id="pai_genero"
                            name="pai_genero"
                            v-model.trim="$v.matricula.pai.genero.$model"
                            style="scroll-margin-top: 50vh"
                          >
                            <option value="" disabled>Selecione o gênero</option>
                            <option v-for="gen in opt_genero" v-bind:key="gen.id" :value="gen.id">
                              {{ gen.name }}
                            </option>
                          </b-form-select>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.genero.$invalid"
                          >
                            <p v-if="$v.matricula.pai.genero.required == false">Campo obrigatório</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" sm="4">
                        <b-form-group label="Profissão" label-for="pai_profissao" label-class="font-weight-bold">
                          <b-form-input
                            v-model.trim="$v.matricula.pai.profissao.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="pai_profissao"
                            name="pai_profissao"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.profissao.$invalid"
                          >
                            <p v-if="$v.matricula.pai.profissao.alpha == false">Campo deve ser alfabético</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="Empresa em que trabalha"
                          label-for="pai_empresa"
                          label-class="font-weight-bold"
                        >
                          <b-form-input
                            v-model.trim="$v.matricula.pai.empresa.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="pai_empresa"
                            name="pai_empresa"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.empresa.$invalid"
                          >
                            <p v-if="$v.matricula.pai.empresa.alpha == false">Campo deve ser alfabético</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group label="Cargo" label-for="pai_cargo" label-class="font-weight-bold">
                          <b-form-input
                            v-model.trim="$v.matricula.pai.cargo.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="pai_cargo"
                            name="pai_cargo"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cargo.$invalid"
                          >
                            <p v-if="$v.matricula.pai.cargo.alpha == false">Campo deve ser alfabético</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="divider">Endereço</div>
                    <div class="text-center mb-2">
                      <b-button pill size="sm" variant="outline-secondary" @click="copiaAlunoPai()">
                        Copiar endereço do aluno
                      </b-button>
                    </div>
                    <b-row>
                      <b-col cols="12" sm="3">
                        <b-form-group label="CEP" label-for="pai_cep" label-class="font-weight-bold required-field">
                          <b-input-group>
                            <the-mask
                              id="pai_cep"
                              name="pai_cep"
                              v-model.trim="$v.matricula.pai.cep.$model"
                              style="scroll-margin-top: 50vh"
                              :mask="'#####-###'"
                              type="text"
                              debounce="1000"
                              placeholder="#####-##"
                              class="form-control"
                            />
                            <template #append>
                              <b-button variant="outline-secondary" @click="buscaCepPai()" :disabled="isLoadingCepPai">
                                <i class="fa-solid fa-magnifying-glass"></i
                              ></b-button>
                            </template>
                          </b-input-group>

                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cep.$invalid"
                          >
                            <p v-if="$v.matricula.pai.cep.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.cep.numeric == false">Campo deve ser numérico</p>
                            <p v-if="$v.matricula.pai.cep.cepSize == false">Cep inválido</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="3">
                        <b-form-group
                          label="Bairro"
                          label-for="pai_bairro"
                          label-class="font-weight-bold required-field"
                        >
                          <b-input-group>
                            <b-form-input
                              v-model.trim="$v.matricula.pai.bairro.$model"
                              style="scroll-margin-top: 50vh"
                              type="text"
                              id="pai_bairro"
                              name="pai_bairro"
                            />
                            <template #append>
                              <b-input-group-text v-if="isLoadingCepPai">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.bairro.$invalid"
                          >
                            <p v-if="$v.matricula.pai.bairro.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.bairro.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="Cidade"
                          label-for="pai_cidade"
                          label-class="font-weight-bold required-field"
                        >
                          <b-input-group>
                            <b-form-input
                              v-model.trim="$v.matricula.pai.cidade.$model"
                              style="scroll-margin-top: 50vh"
                              type="text"
                              id="pai_cidade"
                              name="pai_cidade"
                            />
                            <template #append>
                              <b-input-group-text v-if="isLoadingCepPai">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cidade.$invalid"
                          >
                            <p v-if="$v.matricula.pai.cidade.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.cidade.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="2">
                        <b-form-group label="UF" label-for="pai_estado" label-class="font-weight-bold required-field">
                          <b-input-group>
                            <b-form-input
                              v-model.trim="$v.matricula.pai.estado.$model"
                              style="scroll-margin-top: 50vh"
                              type="text"
                              id="pai_estado"
                              name="pai_estado"
                            />
                            <template #append>
                              <b-input-group-text v-if="isLoadingCepPai">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.estado.$invalid"
                          >
                            <p v-if="$v.matricula.pai.estado.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.estado.alpha == false">Campo deve ser alfabético</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" sm="6">
                        <b-form-group
                          label="Endereço"
                          label-for="pai_endereco"
                          label-class="font-weight-bold required-field"
                        >
                          <b-input-group>
                            <b-form-input
                              v-model.trim="$v.matricula.pai.endereco.$model"
                              style="scroll-margin-top: 50vh"
                              type="text"
                              id="pai_endereco"
                              name="pai_endereco"
                            />
                            <template #append>
                              <b-input-group-text v-if="isLoadingCepPai">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.endereco.$invalid"
                          >
                            <p v-if="$v.matricula.pai.endereco.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.endereco.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="2">
                        <b-form-group
                          label="Número"
                          label-for="pai_numero"
                          label-class="font-weight-bold required-field"
                        >
                          <b-form-input
                            v-model.trim="$v.matricula.pai.numero.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="pai_numero"
                            name="pai_numero"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.numero.$invalid"
                          >
                            <p v-if="$v.matricula.pai.numero.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.pai.numero.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" sm="4">
                        <b-form-group label="Complemento" label-for="pai_complemento" label-class="font-weight-bold">
                          <b-form-input
                            v-model.trim="$v.matricula.pai.complemento.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="pai_complemento"
                            name="pai_complemento"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.pai.complemento.$invalid"
                          >
                            <p v-if="$v.matricula.pai.complemento.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-card
                class="callout-top-primary"
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                no-body
              >
                <template v-slot:header>
                  <h5 class="mb-0">Dados da Mãe</h5>
                  <!-- <b-link @click="copiaAlunoMae()">Copiar endereço do aluno</b-link> -->
                  <b-form-checkbox
                    id="nao_informar_mae"
                    name="nao_informar_mae"
                    v-model="matricula.mae.nao_informar"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Não informar
                  </b-form-checkbox>
                </template>
                <b-card-body class="position-relative">
                  <div v-if="matricula.mae.nao_informar" class="overlay"></div>
                  <div>
                    <b-row>
                      <b-col cols="12" sm="6">
                        <b-form-group
                          label="Nome Completo"
                          label-for="mae_nome"
                          label-class="font-weight-bold required-field"
                        >
                          <b-form-input
                            v-model.trim="$v.matricula.mae.nome.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="mae_nome"
                            name="mae_nome"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.nome.$invalid"
                          >
                            <p v-if="$v.matricula.mae.nome.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.nome.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="6">
                        <b-form-group
                          label="E-mail"
                          label-for="mae_email"
                          label-class="font-weight-bold required-field"
                        >
                          <b-form-input
                            v-model.trim="$v.matricula.mae.email.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="mae_email"
                            name="mae_email"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.email.$invalid"
                          >
                            <p v-if="$v.matricula.mae.email.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.email.email == false">E-mail inválido</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" sm="4" class="text-center">
                        <b-form-group label="Estrangeiro?" label-for="estrangeiro_mae" label-class="font-weight-bold">
                          <b-form-checkbox
                            v-model="matricula.mae.estrangeiro"
                            id="estrangeiro_mae"
                            name="estrangeiro_mae"
                            :value="1"
                            :unchecked-value="0"
                            size="lg"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4" v-show="matricula.mae.estrangeiro">
                        <b-form-group label="RNE" label-for="mae_rne" label-class="font-weight-bold required-field">
                          <b-form-input
                            style="scroll-margin-top: 50vh"
                            id="mae_rne"
                            v-model="$v.matricula.mae.rne.$model"
                            type="text"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.rne.$invalid"
                          >
                            <p v-if="$v.matricula.mae.rne.required == false">Campo obrigatório</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4" v-show="!matricula.mae.estrangeiro">
                        <b-form-group label="CPF" label-for="mae_cpf" label-class="font-weight-bold required-field">
                          <the-mask
                            style="scroll-margin-top: 50vh"
                            id="mae_cpf"
                            v-model.trim="$v.matricula.mae.cpf.$model"
                            :mask="'###.###.###-##'"
                            type="text"
                            debounce="1000"
                            placeholder="###.###.###-##"
                            class="form-control"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cpf.$invalid"
                          >
                            <p v-if="$v.matricula.mae.cpf.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.cpf.cpfSize == false">O cpf deve conter 11 números</p>
                            <p v-if="$v.matricula.mae.cpf.cpfValid == false">CPF Inválido</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="RG"
                          label-for="mae_rg"
                          :label-class="
                            matricula.mae.estrangeiro ? 'font-weight-bold' : 'font-weight-bold required-field'
                          "
                        >
                          <b-form-input
                            style="scroll-margin-top: 50vh"
                            v-model.trim="$v.matricula.mae.rg.$model"
                            type="text"
                            name="mae_rg"
                            id="mae_rg"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.rg.$invalid"
                          >
                            <p v-if="$v.matricula.mae.rg.required == false">Campo obrigatório</p>
                            <!-- <p v-if="$v.matricula.mae.rg.rgValid == false">RG inválido</p> -->
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="Celular"
                          label-for="mae_celular"
                          label-class="font-weight-bold required-field"
                        >
                          <the-mask
                            id="mae_celular"
                            name="mae_celular"
                            style="scroll-margin-top: 50vh"
                            v-model.trim="$v.matricula.mae.celular.$model"
                            :mask="['(##) #####-####']"
                            type="text"
                            placeholder="(##) #####-####"
                            class="form-control"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.celular.$invalid"
                          >
                            <p v-if="$v.matricula.mae.celular.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.celular.celularSize == false">Celular inválido</p>
                            <p v-if="$v.matricula.mae.celular.numeric == false">Campo deve ser numérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="Data de Nascimento"
                          label-for="mae_nascimento"
                          label-class="font-weight-bold required-field"
                        >
                          <date-picker
                            id="mae_nascimento"
                            name="mae_nascimento"
                            style="scroll-margin-top: 50vh"
                            v-model.trim="$v.matricula.mae.nascimento.$model"
                            lang="pt-br"
                            type="date"
                            value-type="DD-MM-YYYY"
                            format="DD-MM-YYYY"
                            input-class="cls-datepicker"
                            :editable="true"
                            :open.sync="openDatePickerMae"
                          >
                            <template v-slot:input>
                              <masked-input
                                type="text"
                                name="mae_nascimento_input"
                                class="cls-datepicker"
                                :value="$v.matricula.mae.nascimento.$model"
                                :mask="dateMask"
                                :guide="false"
                                @change="handleChangeMae"
                              >
                              </masked-input>
                            </template>
                            <template #footer="{ emit }">
                              <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                            </template>
                          </date-picker>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.nascimento.$invalid"
                          >
                            Campo obrigatório
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group label="Gênero" label-for="mae_genero" label-class="font-weight-bold">
                          <b-form-select
                            id="mae_genero"
                            name="mae_genero"
                            v-model.trim="$v.matricula.mae.genero.$model"
                            style="scroll-margin-top: 50vh"
                          >
                            <option value="" disabled>Selecione o gênero</option>
                            <option v-for="gen in opt_genero" v-bind:key="gen.id" :value="gen.id">
                              {{ gen.name }}
                            </option>
                          </b-form-select>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.genero.$invalid"
                          >
                            <p v-if="$v.matricula.mae.genero.required == false">Campo obrigatório</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" sm="4">
                        <b-form-group label="Profissão" label-for="mae_profissao" label-class="font-weight-bold">
                          <b-form-input
                            v-model.trim="$v.matricula.mae.profissao.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="mae_profissao"
                            name="mae_profissao"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.profissao.$invalid"
                          >
                            <p v-if="$v.matricula.mae.profissao.alpha == false">Campo deve ser alfabético</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="Empresa em que trabalha"
                          label-for="mae_empresa"
                          label-class="font-weight-bold"
                        >
                          <b-form-input
                            v-model.trim="$v.matricula.mae.empresa.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="mae_empresa"
                            name="mae_empresa"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.empresa.$invalid"
                          >
                            <p v-if="$v.matricula.mae.empresa.alpha == false">Campo deve ser alfabético</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group label="Cargo" label-for="mae_cargo" label-class="font-weight-bold">
                          <b-form-input
                            v-model.trim="$v.matricula.mae.cargo.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="mae_cargo"
                            name="mae_cargo"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cargo.$invalid"
                          >
                            <p v-if="$v.matricula.mae.cargo.alpha == false">Campo deve ser alfabético</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="divider">Endereço</div>
                    <div class="text-center mb-2">
                      <b-button pill size="sm" variant="outline-secondary" @click="copiaAlunoMae()">
                        Copiar endereço do aluno
                      </b-button>
                    </div>
                    <b-row>
                      <b-col cols="12" sm="3">
                        <b-form-group label="CEP" label-for="mae_cep" label-class="font-weight-bold required-field">
                          <b-input-group>
                            <the-mask
                              id="mae_cep"
                              name="mae_cep"
                              style="scroll-margin-top: 50vh"
                              v-model.trim="$v.matricula.mae.cep.$model"
                              :mask="'#####-###'"
                              type="text"
                              debounce="1000"
                              placeholder="#####-##"
                              class="form-control"
                              @blur.native="buscaCepMae()"
                            />
                            <template #append>
                              <b-button variant="outline-secondary" @click="buscaCepMae()" :disabled="isLoadingCepMae">
                                <i class="fa-solid fa-magnifying-glass"></i
                              ></b-button>
                            </template>
                          </b-input-group>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cep.$invalid"
                          >
                            <p v-if="$v.matricula.mae.cep.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.cep.numeric == false">Campo deve ser numérico</p>
                            <p v-if="$v.matricula.mae.cep.cepSize == false">Cep inválido</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="3">
                        <b-form-group
                          label="Bairro"
                          label-for="mae_bairro"
                          label-class="font-weight-bold required-field"
                        >
                          <b-input-group>
                            <b-form-input
                              v-model.trim="$v.matricula.mae.bairro.$model"
                              style="scroll-margin-top: 50vh"
                              type="text"
                              id="mae_bairro"
                              name="mae_bairro"
                            />
                            <template #append>
                              <b-input-group-text v-if="isLoadingCepMae">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.bairro.$invalid"
                          >
                            <p v-if="$v.matricula.mae.bairro.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.bairro.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="4">
                        <b-form-group
                          label="Cidade"
                          label-for="mae_cidade"
                          label-class="font-weight-bold required-field"
                        >
                          <b-input-group>
                            <b-form-input
                              v-model.trim="$v.matricula.mae.cidade.$model"
                              style="scroll-margin-top: 50vh"
                              type="text"
                              id="mae_cidade"
                              name="mae_cidade"
                            />
                            <template #append>
                              <b-input-group-text v-if="isLoadingCepMae">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cidade.$invalid"
                          >
                            <p v-if="$v.matricula.mae.cidade.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.cidade.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="2">
                        <b-form-group label="UF" label-for="mae_estado" label-class="font-weight-bold required-field">
                          <b-input-group>
                            <b-form-input
                              v-model.trim="$v.matricula.mae.estado.$model"
                              style="scroll-margin-top: 50vh"
                              type="text"
                              id="mae_estado"
                              name="mae_estado"
                            />
                            <template #append>
                              <b-input-group-text v-if="isLoadingCepMae">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.estado.$invalid"
                          >
                            <p v-if="$v.matricula.mae.estado.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.estado.alpha == false">Campo deve ser alfabético</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" sm="6">
                        <b-form-group
                          label="Endereço"
                          label-for="mae_endereco"
                          label-class="font-weight-bold required-field"
                        >
                          <b-input-group>
                            <b-form-input
                              v-model.trim="$v.matricula.mae.endereco.$model"
                              style="scroll-margin-top: 50vh"
                              type="text"
                              id="mae_endereco"
                              name="mae_endereco"
                            />
                            <template #append>
                              <b-input-group-text v-if="isLoadingCepMae">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.endereco.$invalid"
                          >
                            <p v-if="$v.matricula.mae.endereco.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.endereco.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="2">
                        <b-form-group
                          label="Número"
                          label-for="mae_numero"
                          label-class="font-weight-bold required-field"
                        >
                          <b-form-input
                            v-model.trim="$v.matricula.mae.numero.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="mae_numero"
                            name="mae_numero"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.numero.$invalid"
                          >
                            <p v-if="$v.matricula.mae.numero.required == false">Campo obrigatório</p>
                            <p v-if="$v.matricula.mae.numero.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" sm="4">
                        <b-form-group label="Complemento" label-for="mae_complemento" label-class="font-weight-bold">
                          <b-form-input
                            v-model.trim="$v.matricula.mae.complemento.$model"
                            style="scroll-margin-top: 50vh"
                            type="text"
                            id="mae_complemento"
                            name="mae_complemento"
                          />
                          <b-form-invalid-feedback
                            :force-show="submitStatus == 'ERROR' && $v.matricula.mae.complemento.$invalid"
                          >
                            <p v-if="$v.matricula.mae.complemento.alphaNum == false">Campo deve ser alfanumérico</p>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-card
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                class="callout-top-primary"
              >
                <template v-slot:header>
                  <h5 class="mb-0">Responsável Financeiro</h5>
                  <b-link
                    v-if="$v.matricula.responsavel.responsavel.$model === 'outro'"
                    @click="copiaAlunoResponsavel()"
                    >Copiar endereço do aluno</b-link
                  >
                </template>
                <b-row class="d-flex justify-content-center align-items-center">
                  <b-col>
                    <b-form-group
                      label="Responsável Financeiro"
                      label-for="responsavel"
                      label-class="font-weight-bold required-field"
                    >
                      <b-form-radio-group
                        v-model="$v.matricula.responsavel.responsavel.$model"
                        :options="resp_options"
                        name="responsavel-radio"
                      ></b-form-radio-group>

                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.responsavel.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.responsavel.required == false">Campo obrigatório</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="$v.matricula.responsavel.responsavel.$model === 'outro'">
                    <b-form-group
                      label="Outro Responsável"
                      label-for="outro_responsavel"
                      label-class="font-weight-bold required-field"
                    >
                      <b-form-input
                        type="text"
                        v-model.trim="$v.matricula.responsavel.outro_responsavel.$model"
                        id="outro_responsavel"
                        name="outro_responsavel"
                        placeholder="Ex. Avós, Tios, Irmãos.. etc..."
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.outro_responsavel.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.outro_responsavel.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.outro_responsavel.alpha == false">
                          Campo deve ser alfabético
                        </p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-card
                class="callout-top-primary"
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                v-show="$v.matricula.responsavel.responsavel.$model === 'outro'"
              >
                <template v-slot:header>
                  <h5 class="mb-0">Dados do Responsável Financeiro</h5>
                </template>

                <b-row>
                  <b-col cols="12" sm="6">
                    <b-form-group
                      label="Nome Completo"
                      label-for="responsavel_nome"
                      label-class="font-weight-bold required-field"
                    >
                      <b-form-input
                        v-model.trim="$v.matricula.responsavel.nome.$model"
                        type="text"
                        id="responsavel_nome"
                        name="responsavel_nome"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.nome.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.nome.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.nome.alphaNum == false">Campo deve ser alfanumérico</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <b-form-group
                      label="E-mail"
                      label-for="responsavel_email"
                      label-class="font-weight-bold required-field"
                    >
                      <b-form-input
                        v-model.trim="$v.matricula.responsavel.email.$model"
                        type="text"
                        id="responsavel_email"
                        name="responsavel_email"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.email.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.email == false">E-mail inválido</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" sm="4" class="text-center">
                    <b-form-group
                      label="Estrangeiro?"
                      label-for="estrangeiro_responsavel"
                      label-class="font-weight-bold"
                    >
                      <b-form-checkbox
                        v-model="matricula.responsavel.estrangeiro"
                        id="estrangeiro_responsavel"
                        name="estrangeiro_responsavel"
                        :value="1"
                        :unchecked-value="0"
                        size="lg"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4" v-show="matricula.responsavel.estrangeiro">
                    <b-form-group label="RNE" label-for="responsavel_rne" label-class="font-weight-bold required-field">
                      <b-form-input
                        style="scroll-margin-top: 50vh"
                        id="responsavel_rne"
                        v-model="$v.matricula.responsavel.rne.$model"
                        type="text"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.rne.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.rne.required == false">Campo obrigatório</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4" v-show="!matricula.responsavel.estrangeiro">
                    <b-form-group label="CPF" label-for="responsavel_cpf" label-class="font-weight-bold required-field">
                      <the-mask
                        id="responsavel_cpf"
                        v-model.trim="$v.matricula.responsavel.cpf.$model"
                        :mask="'###.###.###-##'"
                        type="text"
                        debounce="1000"
                        placeholder="###.###.###-##"
                        class="form-control"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cpf.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.cpf.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.cpf.cpfSize == false">O cpf deve conter 11 números</p>
                        <p v-if="$v.matricula.responsavel.cpf.cpfValid == false">CPF Inválido</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="RG"
                      label-for="responsavel_rg"
                      :label-class="
                        matricula.responsavel.estrangeiro ? 'font-weight-bold' : 'font-weight-bold required-field'
                      "
                    >
                      <b-form-input
                        v-model.trim="$v.matricula.responsavel.rg.$model"
                        type="text"
                        name="responsavel_rg"
                        id="responsavel_rg"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.rg.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.rg.required == false">Campo obrigatório</p>
                        <!-- <p v-if="$v.matricula.responsavel.rg.rgValid == false">RG inválido</p> -->
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="Celular"
                      label-for="responsavel_celular"
                      label-class="font-weight-bold required-field"
                    >
                      <the-mask
                        id="responsavel_celular"
                        name="responsavel_celular"
                        v-model.trim="$v.matricula.responsavel.celular.$model"
                        :mask="['(##) #####-####']"
                        type="text"
                        placeholder="(##) #####-####"
                        class="form-control"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.celular.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.celular.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.celular.celularSize == false">Celular inválido</p>
                        <p v-if="$v.matricula.responsavel.celular.numeric == false">Campo deve ser numérico</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="Data de Nascimento"
                      label-for="responsavel_nascimento"
                      label-class="font-weight-bold required-field"
                    >
                      <date-picker
                        id="responsavel_nascimento"
                        name="responsavel_nascimento"
                        v-model.trim="$v.matricula.responsavel.nascimento.$model"
                        lang="pt-br"
                        type="date"
                        value-type="DD-MM-YYYY"
                        format="DD-MM-YYYY"
                        input-class="cls-datepicker"
                        :editable="true"
                        :open.sync="openDatePickerResponsavel"
                      >
                        <template v-slot:input>
                          <masked-input
                            type="text"
                            name="responsavel_nascimento_input"
                            class="cls-datepicker"
                            :value="$v.matricula.responsavel.nascimento.$model"
                            :mask="dateMask"
                            :guide="false"
                            @change="handleChangeResponsavel"
                          >
                          </masked-input>
                        </template>
                        <template #footer="{ emit }">
                          <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                        </template>
                      </date-picker>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.nascimento.$invalid"
                      >
                        Campo obrigatório
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4">
                    <b-form-group label="Gênero" label-for="responsavel_genero" label-class="font-weight-bold">
                      <b-form-select
                        id="responsavel_genero"
                        name="responsavel_genero"
                        v-model.trim="$v.matricula.responsavel.genero.$model"
                      >
                        <option value="" disabled>Selecione o gênero</option>
                        <option v-for="gen in opt_genero" v-bind:key="gen.id" :value="gen.id">{{ gen.name }}</option>
                      </b-form-select>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.genero.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.genero.required == false">Campo obrigatório</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" sm="4">
                    <b-form-group label="Profissão" label-for="responsavel_profissao" label-class="font-weight-bold">
                      <b-form-input
                        v-model.trim="$v.matricula.responsavel.profissao.$model"
                        type="text"
                        id="responsavel_profissao"
                        name="responsavel_profissao"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.profissao.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.profissao.alpha == false">Campo deve ser alfabético</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="Empresa em que trabalha"
                      label-for="responsavel_empresa"
                      label-class="font-weight-bold"
                    >
                      <b-form-input
                        v-model.trim="$v.matricula.responsavel.empresa.$model"
                        type="text"
                        id="responsavel_empresa"
                        name="responsavel_empresa"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.empresa.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.empresa.alpha == false">Campo deve ser alfabético</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4">
                    <b-form-group label="Cargo" label-for="responsavel_cargo" label-class="font-weight-bold">
                      <b-form-input
                        v-model.trim="$v.matricula.responsavel.cargo.$model"
                        type="text"
                        id="responsavel_cargo"
                        name="responsavel_cargo"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cargo.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.cargo.alpha == false">Campo deve ser alfabético</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="divider">Endereço</div>
                <b-row>
                  <b-col cols="12" sm="3">
                    <b-form-group label="CEP" label-for="responsavel_cep" label-class="font-weight-bold required-field">
                      <b-input-group>
                        <the-mask
                          id="responsavel_cep"
                          name="responsavel_cep"
                          v-model.trim="$v.matricula.responsavel.cep.$model"
                          :mask="'#####-###'"
                          type="text"
                          debounce="1000"
                          placeholder="#####-##"
                          class="form-control"
                          @blur.native="buscaCepResponsavel()"
                        />
                        <template #append>
                          <b-button
                            variant="outline-secondary"
                            @click="buscaCepResponsavel()"
                            :disabled="isLoadingCepResp"
                          >
                            <i class="fa-solid fa-magnifying-glass"></i
                          ></b-button>
                        </template>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cep.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.cep.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.cep.numeric == false">Campo deve ser numérico</p>
                        <p v-if="$v.matricula.responsavel.cep.cepSize == false">Cep inválido</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="3">
                    <b-form-group
                      label="Bairro"
                      label-for="responsavel_bairro"
                      label-class="font-weight-bold required-field"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model.trim="$v.matricula.responsavel.bairro.$model"
                          type="text"
                          id="responsavel_bairro"
                          name="responsavel_bairro"
                        />
                        <template #append>
                          <b-input-group-text v-if="isLoadingCepResp">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.bairro.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.bairro.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.bairro.alphaNum == false">Campo deve ser alfanumérico</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="Cidade"
                      label-for="responsavel_cidade"
                      label-class="font-weight-bold required-field"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model.trim="$v.matricula.responsavel.cidade.$model"
                          type="text"
                          id="responsavel_cidade"
                          name="responsavel_cidade"
                        />
                        <template #append>
                          <b-input-group-text v-if="isLoadingCepResp">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cidade.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.cidade.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.cidade.alphaNum == false">Campo deve ser alfanumérico</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="2">
                    <b-form-group
                      label="UF"
                      label-for="responsavel_estado"
                      label-class="font-weight-bold required-field"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model.trim="$v.matricula.responsavel.estado.$model"
                          type="text"
                          id="responsavel_estado"
                          name="responsavel_estado"
                        />
                        <template #append>
                          <b-input-group-text v-if="isLoadingCepResp">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.estado.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.estado.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.estado.alpha == false">Campo deve ser alfabético</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" sm="6">
                    <b-form-group
                      label="Endereço"
                      label-for="responsavel_endereco"
                      label-class="font-weight-bold required-field"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model.trim="$v.matricula.responsavel.endereco.$model"
                          type="text"
                          id="responsavel_endereco"
                          name="responsavel_endereco"
                        />
                        <template #append>
                          <b-input-group-text v-if="isLoadingCepResp">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.endereco.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.endereco.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.endereco.alphaNum == false">Campo deve ser alfanumérico</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="2">
                    <b-form-group
                      label="Número"
                      label-for="responsavel_numero"
                      label-class="font-weight-bold required-field"
                    >
                      <b-form-input
                        v-model.trim="$v.matricula.responsavel.numero.$model"
                        type="text"
                        id="responsavel_numero"
                        name="responsavel_numero"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.numero.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.numero.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.responsavel.numero.alphaNum == false">Campo deve ser alfanumérico</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="4">
                    <b-form-group
                      label="Complemento"
                      label-for="responsavel_complemento"
                      label-class="font-weight-bold"
                    >
                      <b-form-input
                        v-model.trim="$v.matricula.responsavel.complemento.$model"
                        type="text"
                        id="responsavel_complemento"
                        name="responsavel_complemento"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.complemento.$invalid"
                      >
                        <p v-if="$v.matricula.responsavel.responsavel.alphaNum == false">Campo deve ser alfanumérico</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <b-card
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                class="callout-top-primary"
              >
                <template v-slot:header>
                  <h5 class="mb-0">Ficha preenchida por:</h5>
                </template>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Ficha preenchida por"
                      label-for="preenchida_por"
                      label-class="font-weight-bold required-field"
                    >
                      <b-form-radio-group
                        v-model="$v.matricula.gerais.preenchido_por.$model"
                        :options="preenchido_por_options"
                        name="preenchida-por-radio"
                      ></b-form-radio-group>

                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.preenchido_por.$invalid"
                      >
                        Campo obrigatório
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Outro"
                      label-for="preenchido_por_outro"
                      label-class="font-weight-bold required-field"
                      v-show="$v.matricula.gerais.preenchido_por.$model === 'outro'"
                    >
                      <b-form-input
                        type="text"
                        v-model.trim="$v.matricula.gerais.preenchido_por_outro.$model"
                        id="preenchido_por_outro"
                        name="preenchido_por_outro"
                      />
                      <b-form-invalid-feedback
                        :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.preenchido_por_outro.$invalid"
                      >
                        <p v-if="$v.matricula.gerais.preenchido_por_outro.required == false">Campo obrigatório</p>
                        <p v-if="$v.matricula.gerais.preenchido_por_outro.alpha == false">Campo deve ser alfabético</p>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="mt-3" v-if="1 == 0">
            <b-col>
              <b-card
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                class="callout-top-primary"
              >
                <template v-slot:header>
                  <h5 class="mb-0">Plano</h5>
                </template>
                <div v-if="filteredOfertas.length == 0">Selecione a unidade onde deseja estudar</div>
                <b-table-simple v-else>
                  <b-thead>
                    <b-tr>
                      <b-th class="text-center">Selecione</b-th>
                      <b-th>Investimento</b-th>
                      <b-th>Plano</b-th>
                      <b-th>Desconto</b-th>
                      <b-th>Parcelamento</b-th>
                      <b-th>Total</b-th>
                      <b-th>Validade</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="oferta in filteredOfertas" v-bind:key="oferta.id">
                      <b-td class="text-center">
                        <b-form-radio
                          v-model="$v.matricula.gerais.turma_oferta_id.$model"
                          name="turma-oferta"
                          :value="oferta.turma_oferta_id"
                        ></b-form-radio>
                      </b-td>
                      <b-td>R$ {{ oferta.valor_tabela | formatDecimalNumber }}</b-td>
                      <b-td>{{ oferta.nome_oferta }}</b-td>

                      <b-td>
                        <span v-if="oferta.tipo_desconto == 'valor'"
                          >R$ {{ oferta.desconto_valor | formatDecimalNumber }}</span
                        >
                        <span v-if="oferta.tipo_desconto == 'percentual'"
                          >{{ oferta.desconto_percentual | formatDecimalNumber }}%</span
                        >
                      </b-td>
                      <b-td>
                        {{ oferta.qtd_parcelas }} x R$
                        {{ (oferta.valor_total_oferta / oferta.qtd_parcelas) | formatDecimalNumber }}
                      </b-td>
                      <b-td>R$ {{ oferta.valor_total_oferta | formatDecimalNumber }} </b-td>
                      <b-td>
                        {{ oferta.oferta_fim | formatShortDate }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-form-group>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.termos_aceitos.$invalid"
                >
                  <p v-if="$v.matricula.gerais.termos_aceitos.termsAccepted == false">
                    É necessário aceitar os termos para continuar
                  </p>
                </b-form-invalid-feedback>
                <b-form-checkbox
                  v-model="$v.matricula.gerais.termos_aceitos.$model"
                  name="termos_aceitos"
                  id="termos_aceitos"
                  switch
                >
                  Li e concordo com o
                  <a
                    href="https://media.cpv.com.br/sitecpv/contrato-exemplo/contrato_do_aluno_generico.pdf"
                    style="text-decoration-line: underline"
                    target="_blank"
                    >Contrato de Prestação de Serviços Educacionais</a
                  >
                  disponibilizados no item 6 e estou ciente de que o preenchimento e envio da Ficha de Matrícula não a
                  efetiva nem representa Reserva de Vaga. Também estou ciente de que minha matrícula somente estará
                  efetivada após o pagamento da primeira parcela e entrega de toda a documentação exigida.
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- <b-button variant="primary" class="float-btn" type="submit"> Enviar </b-button> -->

          <b-row class="mt-3">
            <b-col>
              <b-button v-if="!submiting" block variant="primary" type="submit">Enviar</b-button>
              <b-button v-else block variant="primary">
                <i class="fa-solid fa-spinner fa-spin"></i>
                Enviando
              </b-button>
            </b-col>
          </b-row>

          <!-- Mensagens sobre perguntas extras -->
          <b-row class="mt-3" v-for="(question, index) in matricula.questions" v-bind:key="index">
            <b-col>
              <b-link :href="`#question_${question.id}`">
                <b-form-invalid-feedback
                  :force-show="
                    question.required == 1 &&
                    submitStatus == 'ERROR' &&
                    $v.matricula.questions.$each.$iter[index].value.$invalid
                  "
                >
                  <p v-if="$v.matricula.questions.$each.$iter[index].value.required == false">
                    É preciso responder a questão: {{ question.statement }}
                  </p>
                </b-form-invalid-feedback>
              </b-link>
            </b-col>
          </b-row>
          <!--  Mensagens -->
          <b-row class="mt-3">
            <b-col
              cols="12"
              v-if="$v.matricula.aluno.$invalid || $v.matricula.gerais.$invalid"
              :sm="$v.matricula.responsavel.responsavel.$model === 'outro' ? 3 : 4"
            >
              <b-link href="#gerais_unidade">
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.unidade_id.$invalid"
                >
                  <p>A UNIDADE é obrigatória</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_nome">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.nome.$invalid">
                  <p v-if="$v.matricula.aluno.nome.required == false">Nome do Aluno é obrigatório</p>
                  <p v-if="$v.matricula.aluno.nome.alphaNum == false">
                    Nome do Aluno deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.aluno.nome.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_email">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.email.$invalid">
                  <p v-if="$v.matricula.aluno.email.required == false">O EMAIL do Aluno é obrigatório</p>
                  <p v-if="$v.matricula.aluno.email.email == false">O EMAIL do Aluno está inválido</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_cpf">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cpf.$invalid">
                  <p v-if="$v.matricula.aluno.cpf.required == false">O CPF do Aluno é obrigatório</p>
                  <p v-if="$v.matricula.aluno.cpf.cpfSize == false">O CPF do Aluno deve conter 11 números</p>
                  <p v-if="$v.matricula.aluno.cpf.cpfValid == false">CPF do Aluno está Inválido</p>
                  <p v-if="$v.matricula.aluno.cpf.numeric == false">CPF do Aluno deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_rne">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.rne.$invalid">
                  <p v-if="$v.matricula.aluno.rne.required == false">O RNE do Aluno é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_rg">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.rg.$invalid">
                  <p v-if="$v.matricula.aluno.rg.required == false">O RG do Aluno é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_celular">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.celular.$invalid">
                  <p v-if="$v.matricula.aluno.celular.required == false">O CELULAR do Aluno é obrigatório</p>
                  <p v-if="$v.matricula.aluno.celular.celularSize == false">O CELULAR do Aluno está inválido</p>
                  <p v-if="$v.matricula.aluno.celular.numeric == false">O CELULAR do Aluno deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_nascimento">
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.nascimento.$invalid"
                >
                  <p>A DATA DE NASCIMENTO do Aluno é obrigatória</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_genero">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.genero.$invalid">
                  <p v-if="$v.matricula.aluno.genero.required == false">O GÊNERO do Aluno é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_escola">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.escola.$invalid">
                  <p v-if="$v.matricula.aluno.escola.required == false">A ESCOLA do Aluno é obrigatória</p>
                  <p v-if="$v.matricula.aluno.escola.alphaNum == false">
                    A ESCOLA do Aluno deve ser alfanumérica<br />
                    <span v-html="validateTextAlphaNum($v.matricula.aluno.escola.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_cursinho">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cursinho.$invalid">
                  <p v-if="$v.matricula.aluno.cursinho.alphaNum == false">
                    O CURSINHO do Aluno deve ser alfanumérica<br />
                    <span v-html="validateTextAlphaNum($v.matricula.aluno.cursinho.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_cep">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cep.$invalid">
                  <p v-if="$v.matricula.aluno.cep.required == false">O CEP do Aluno é obrigatório</p>
                  <p v-if="$v.matricula.aluno.cep.numeric == false">O CEP do Aluno deve ser numérico</p>
                  <p v-if="$v.matricula.aluno.cep.cepSize == false">O CEP do Aluno está inválido</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_bairro">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.bairro.$invalid">
                  <p v-if="$v.matricula.aluno.bairro.required == false">O BAIRRO do Aluno é obrigatório</p>
                  <p v-if="$v.matricula.aluno.bairro.alphaNum == false">
                    O BAIRRO do Aluno deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.aluno.bairro.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_cidade">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cidade.$invalid">
                  <p v-if="$v.matricula.aluno.cidade.required == false">A CIDADE do Aluno é obrigatória</p>
                  <p v-if="$v.matricula.aluno.cidade.alphaNum == false">
                    A CIDADE do Aluno deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.aluno.cidade.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_estado">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.estado.$invalid">
                  <p v-if="$v.matricula.aluno.estado.required == false">O ESTADO do Aluno é obrigatório</p>
                  <p v-if="$v.matricula.aluno.estado.alpha == false">
                    O ESTADO do Aluno deve ser alfabético<br />
                    <span v-html="validateTextAlpha($v.matricula.aluno.estado.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_endereco">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.endereco.$invalid">
                  <p v-if="$v.matricula.aluno.endereco.required == false">O ENDEREÇO do Aluno é obrigatório</p>
                  <p v-if="$v.matricula.aluno.endereco.alphaNum == false">
                    O ENDEREÇO do Aluno deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.aluno.endereco.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_numero">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.numero.$invalid">
                  <p v-if="$v.matricula.aluno.numero.required == false">O NÚMERO do ENDEREÇO do Aluno é obrigatório</p>
                  <p v-if="$v.matricula.aluno.numero.alphaNum == false">
                    O NÚMERO do ENDEREÇO do Aluno deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.aluno.numero.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#aluno_complemento">
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.complemento.$invalid"
                >
                  <p v-if="$v.matricula.aluno.complemento.alphaNum == false">
                    O COMPLEMENTO do ENDEREÇO do Aluno deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.aluno.complemento.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
            </b-col>
            <b-col
              cols="12"
              v-if="$v.matricula.pai.$invalid"
              :sm="$v.matricula.responsavel.responsavel.$model === 'outro' ? 3 : 4"
            >
              <b-link href="#pai_nome">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.nome.$invalid">
                  <p v-if="$v.matricula.pai.nome.required == false">Nome do Pai é obrigatório</p>
                  <p v-if="$v.matricula.pai.nome.alphaNum == false">
                    Nome do Pai deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.pai.nome.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_email">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.email.$invalid">
                  <p v-if="$v.matricula.pai.email.required == false">O EMAIL do Pai é obrigatório</p>
                  <p v-if="$v.matricula.pai.email.email == false">O EMAIL do Pai está inválido</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_cpf">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cpf.$invalid">
                  <p v-if="$v.matricula.pai.cpf.required == false">O CPF do Pai é obrigatório</p>
                  <p v-if="$v.matricula.pai.cpf.cpfSize == false">O CPF do Pai deve conter 11 números</p>
                  <p v-if="$v.matricula.pai.cpf.cpfValid == false">CPF do Pai está Inválido</p>
                  <p v-if="$v.matricula.pai.cpf.numeric == false">CPF do Pai deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_rne">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.rne.$invalid">
                  <p v-if="$v.matricula.pai.rne.required == false">O RNE do Pai é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_rg">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.rg.$invalid">
                  <p v-if="$v.matricula.pai.rg.required == false">O RG do Pai é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_celular">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.celular.$invalid">
                  <p v-if="$v.matricula.pai.celular.required == false">O CELULAR do Pai é obrigatório</p>
                  <p v-if="$v.matricula.pai.celular.celularSize == false">O CELULAR do Pai está inválido</p>
                  <p v-if="$v.matricula.pai.celular.numeric == false">O CELULAR do Pai deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_nascimento">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.nascimento.$invalid">
                  <p>A DATA DE NASCIMENTO do Pai é obrigatória</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_genero">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.genero.$invalid">
                  <p v-if="$v.matricula.pai.genero.required == false">O GÊNERO do Pai é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_profissao">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.profissao.$invalid">
                  <p v-if="$v.matricula.pai.profissao.alpha == false">
                    A PROFISSÃO do Pai é inválida<br />
                    <span v-html="validateTextAlpha($v.matricula.pai.profissao.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_empresa">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.empresa.$invalid">
                  <p v-if="$v.matricula.pai.empresa.alpha == false">
                    A EMPRESA do Pai é inválida<br />
                    <span v-html="validateTextAlpha($v.matricula.pai.empresa.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_cargo">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cargo.$invalid">
                  <p v-if="$v.matricula.pai.cargo.alpha == false">
                    O CARGO do Pai é inválido<br />
                    <span v-html="validateTextAlpha($v.matricula.pai.cargo.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_cep">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cep.$invalid">
                  <p v-if="$v.matricula.pai.cep.required == false">O CEP do Pai é obrigatório</p>
                  <p v-if="$v.matricula.pai.cep.numeric == false">O CEP do Pai deve ser numérico</p>
                  <p v-if="$v.matricula.pai.cep.cepSize == false">O CEP do Pai está inválido</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_bairro">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.bairro.$invalid">
                  <p v-if="$v.matricula.pai.bairro.required == false">O BAIRRO do Pai é obrigatório</p>
                  <p v-if="$v.matricula.pai.bairro.alphaNum == false">
                    O BAIRRO do Pai deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.pai.bairro.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_cidade">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cidade.$invalid">
                  <p v-if="$v.matricula.pai.cidade.required == false">A CIDADE do Pai é obrigatória</p>
                  <p v-if="$v.matricula.pai.cidade.alphaNum == false">
                    A CIDADE do Pai deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.pai.cidade.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_estado">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.estado.$invalid">
                  <p v-if="$v.matricula.pai.estado.required == false">O ESTADO do Pai é obrigatório</p>
                  <p v-if="$v.matricula.pai.estado.alpha == false">
                    O ESTADO do Pai deve ser alfabético<br />
                    <span v-html="validateTextAlpha($v.matricula.pai.estado.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_endereco">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.endereco.$invalid">
                  <p v-if="$v.matricula.pai.endereco.required == false">O ENDEREÇO do Pai é obrigatório</p>
                  <p v-if="$v.matricula.pai.endereco.alphaNum == false">
                    O ENDEREÇO do Pai deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.pai.endereco.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_numero">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.numero.$invalid">
                  <p v-if="$v.matricula.pai.numero.required == false">O NÚMERO do ENDEREÇO do Pai é obrigatório</p>
                  <p v-if="$v.matricula.pai.numero.alphaNum == false">
                    O NÚMERO do ENDEREÇO do Pai deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.pai.numero.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#pai_complemento">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.complemento.$invalid">
                  <p v-if="$v.matricula.pai.complemento.alphaNum == false">
                    O COMPLEMENTO do ENDEREÇO da Pai deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.pai.complemento.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
            </b-col>
            <b-col
              cols="12"
              v-if="$v.matricula.mae.$invalid"
              :sm="$v.matricula.responsavel.responsavel.$model === 'outro' ? 3 : 4"
            >
              <b-link href="#mae_nome">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.nome.$invalid">
                  <p v-if="$v.matricula.mae.nome.required == false">NOME da Mãe é obrigatório</p>
                  <p v-if="$v.matricula.mae.nome.alphaNum == false">
                    NOME da Mãe deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.mae.nome.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_email">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.email.$invalid">
                  <p v-if="$v.matricula.mae.email.required == false">O EMAIL da Mãe é obrigatório</p>
                  <p v-if="$v.matricula.mae.email.email == false">O EMAIL da Mãe está inválido</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_cpf">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cpf.$invalid">
                  <p v-if="$v.matricula.mae.cpf.required == false">O CPF da Mãe é obrigatório</p>
                  <p v-if="$v.matricula.mae.cpf.cpfSize == false">O CPF da Mãe deve conter 11 números</p>
                  <p v-if="$v.matricula.mae.cpf.cpfValid == false">CPF da Mãe está Inválido</p>
                  <p v-if="$v.matricula.mae.cpf.numeric == false">CPF da Mãe deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_rne">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.rne.$invalid">
                  <p v-if="$v.matricula.mae.rne.required == false">O RNE da Mãe é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_rg">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.rg.$invalid">
                  <p v-if="$v.matricula.mae.rg.required == false">O RG da Mãe é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_celular">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.celular.$invalid">
                  <p v-if="$v.matricula.mae.celular.required == false">O CELULAR da Mãe é obrigatório</p>
                  <p v-if="$v.matricula.mae.celular.celularSize == false">O CELULAR da Mãe está inválido</p>
                  <p v-if="$v.matricula.mae.celular.numeric == false">O CELULAR da Mãe deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_nascimento">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.nascimento.$invalid">
                  <p>A DATA DE NASCIMENTO da Mãe é obrigatória</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_genero">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.genero.$invalid">
                  <p v-if="$v.matricula.mae.genero.required == false">O GÊNERO da Mãe é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_profissao">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.profissao.$invalid">
                  <p v-if="$v.matricula.mae.profissao.alpha == false">
                    A PROFISSÃO da Mãe é inválida<br />
                    <span v-html="validateTextAlpha($v.matricula.mae.profissao.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_empresa">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.empresa.$invalid">
                  <p v-if="$v.matricula.mae.empresa.alpha == false">
                    A EMPRESA da Mãe é inválida<br />
                    <span v-html="validateTextAlpha($v.matricula.mae.empresa.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_cargo">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cargo.$invalid">
                  <p v-if="$v.matricula.mae.cargo.alpha == false">
                    O CARGO da Mãe é inválido<br />
                    <span v-html="validateTextAlpha($v.matricula.mae.cargo.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_cep">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cep.$invalid">
                  <p v-if="$v.matricula.mae.cep.required == false">O CEP da Mãe é obrigatório</p>
                  <p v-if="$v.matricula.mae.cep.numeric == false">O CEP da Mãe deve ser numérico</p>
                  <p v-if="$v.matricula.mae.cep.cepSize == false">O CEP da Mãe está inválido</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_bairro">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.bairro.$invalid">
                  <p v-if="$v.matricula.mae.bairro.required == false">O BAIRRO da Mãe é obrigatório</p>
                  <p v-if="$v.matricula.mae.bairro.alphaNum == false">
                    O BAIRRO da Mãe deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.mae.bairro.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_cidade">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cidade.$invalid">
                  <p v-if="$v.matricula.mae.cidade.required == false">A CIDADE da Mãe é obrigatória</p>
                  <p v-if="$v.matricula.mae.cidade.alphaNum == false">
                    A CIDADE da Mãe deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.mae.cidade.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_estado">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.estado.$invalid">
                  <p v-if="$v.matricula.mae.estado.required == false">O ESTADO da Mãe é obrigatório</p>
                  <p v-if="$v.matricula.mae.estado.alpha == false">
                    O ESTADO da Mãe deve ser alfabético<br />
                    <span v-html="validateTextAlpha($v.matricula.mae.estado.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_endereco">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.endereco.$invalid">
                  <p v-if="$v.matricula.mae.endereco.required == false">O ENDEREÇO da Mãe é obrigatório</p>
                  <p v-if="$v.matricula.mae.endereco.alphaNum == false">
                    O ENDEREÇO da Mãe deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.mae.endereco.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_numero">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.numero.$invalid">
                  <p v-if="$v.matricula.mae.numero.required == false">O NÚMERO do ENDEREÇO da Mãe é obrigatório</p>
                  <p v-if="$v.matricula.mae.numero.alphaNum == false">
                    O NÚMERO do ENDEREÇO da Mãe deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.mae.numero.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
              <b-link href="#mae_complemento">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.complemento.$invalid">
                  <p v-if="$v.matricula.mae.complemento.alphaNum == false">
                    O COMPLEMENTO do ENDEREÇO da Mãe deve ser alfanumérico<br />
                    <span v-html="validateTextAlphaNum($v.matricula.mae.complemento.$model)"></span>
                  </p>
                </b-form-invalid-feedback>
              </b-link>
            </b-col>
            <b-col
              cols="12"
              sm="3"
              v-if="$v.matricula.responsavel.$invalid && $v.matricula.responsavel.responsavel.$model === 'outro'"
            >
              <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.nome.$invalid">
                <p v-if="$v.matricula.responsavel.nome.required == false">NOME do responsável é obrigatório</p>
                <p v-if="$v.matricula.responsavel.nome.alphaNum == false">
                  NOME do responsável deve ser alfanumérico<br />
                  <span v-html="validateTextAlphaNum($v.matricula.responsavel.nome.$model)"></span>
                </p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.email.$invalid">
                <p v-if="$v.matricula.responsavel.email.required == false">O EMAIL do responsável é obrigatório</p>
                <p v-if="$v.matricula.responsavel.email.email == false">O EMAIL do responsável está inválido</p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cpf.$invalid">
                <p v-if="$v.matricula.responsavel.cpf.required == false">O CPF do responsável é obrigatório</p>
                <p v-if="$v.matricula.responsavel.cpf.cpfSize == false">O CPF do responsável deve conter 11 números</p>
                <p v-if="$v.matricula.responsavel.cpf.cpfValid == false">O CPF do responsável está Inválido</p>
                <p v-if="$v.matricula.responsavel.cpf.numeric == false">O CPF do responsável deve ser numérico</p>
              </b-form-invalid-feedback>
              <b-link href="#responsavel_rne">
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.rne.$invalid">
                  <p v-if="$v.matricula.responsavel.rne.required == false">O RNE do responsável é obrigatório</p>
                </b-form-invalid-feedback>
              </b-link>
              <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.rg.$invalid">
                <p v-if="$v.matricula.responsavel.rg.required == false">O RG do responsável é obrigatório</p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.celular.$invalid"
              >
                <p v-if="$v.matricula.responsavel.celular.required == false">O CELULAR do responsável é obrigatório</p>
                <p v-if="$v.matricula.responsavel.celular.celularSize == false">
                  O CELULAR do responsável está inválido
                </p>
                <p v-if="$v.matricula.responsavel.celular.numeric == false">
                  O CELULAR do responsável deve ser numérico
                </p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.nascimento.$invalid"
              >
                <p>A DATA DE NASCIMENTO do responsável é obrigatória</p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.genero.$invalid"
              >
                <p v-if="$v.matricula.responsavel.genero.required == false">O GÊNERO do responsável é obrigatório</p>
              </b-form-invalid-feedback>

              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.profissao.$invalid"
              >
                <p v-if="$v.matricula.responsavel.profissao.alpha == false">
                  A PROFISSÃO do responsável é inválida<br />
                  <span v-html="validateTextAlpha($v.matricula.responsavel.profissao.$model)"></span>
                </p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.empresa.$invalid"
              >
                <p v-if="$v.matricula.responsavel.empresa.alpha == false">
                  A EMPRESA do responsável é inválida<br />
                  <span v-html="validateTextAlpha($v.matricula.responsavel.empresa.$model)"></span>
                </p>
              </b-form-invalid-feedback>

              <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cep.$invalid">
                <p v-if="$v.matricula.responsavel.cep.required == false">O CEP do responsável é obrigatório</p>
                <p v-if="$v.matricula.responsavel.cep.numeric == false">O CEP do responsável deve ser numérico</p>
                <p v-if="$v.matricula.responsavel.cep.cepSize == false">O CEP do responsável está inválido</p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.bairro.$invalid"
              >
                <p v-if="$v.matricula.responsavel.bairro.required == false">O BAIRRO do responsável é obrigatório</p>
                <p v-if="$v.matricula.responsavel.bairro.alphaNum == false">
                  O BAIRRO do responsável deve ser alfanumérico<br />
                  <span v-html="validateTextAlphaNum($v.matricula.responsavel.bairro.$model)"></span>
                </p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cidade.$invalid"
              >
                <p v-if="$v.matricula.responsavel.cidade.required == false">A CIDADE do responsável é obrigatória</p>
                <p v-if="$v.matricula.responsavel.cidade.alphaNum == false">
                  A CIDADE do responsável deve ser alfanumérico<br />
                  <span v-html="validateTextAlphaNum($v.matricula.responsavel.cidade.$model)"></span>
                </p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.estado.$invalid"
              >
                <p v-if="$v.matricula.responsavel.estado.required == false">O ESTADO do responsável é obrigatório</p>
                <p v-if="$v.matricula.responsavel.estado.alpha == false">
                  O ESTADO do responsável deve ser alfabético<br />
                  <span v-html="validateTextAlpha($v.matricula.responsavel.estado.$model)"></span>
                </p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.endereco.$invalid"
              >
                <p v-if="$v.matricula.responsavel.endereco.required == false">
                  O ENDEREÇO do responsável é obrigatório
                </p>
                <p v-if="$v.matricula.responsavel.endereco.alphaNum == false">
                  O ENDEREÇO do responsável deve ser alfanumérico<br />
                  <span v-html="validateTextAlphaNum($v.matricula.responsavel.endereco.$model)"></span>
                </p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.numero.$invalid"
              >
                <p v-if="$v.matricula.responsavel.numero.required == false">
                  O NÚMERO do ENDEREÇO do responsável é obrigatório
                </p>
                <p v-if="$v.matricula.responsavel.numero.alphaNum == false">
                  O NÚMERO do ENDEREÇO do responsável deve ser alfanumérico<br />
                  <span v-html="validateTextAlphaNum($v.matricula.responsavel.numero.$model)"></span>
                </p>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.complemento.$invalid"
              >
                <p v-if="$v.matricula.responsavel.complemento.alphaNum == false">
                  O COMPLEMENTO do ENDEREÇO do responsável deve ser alfanumérico<br />
                  <span v-html="validateTextAlphaNum($v.matricula.responsavel.complemento.$model)"></span>
                </p>
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import BreadCpv from "@/components/global/bread-cpv.vue";
import JumbotronCourse from "@/components/global/components_cursos/jumbotron_courses";

import {
  FormPlugin,
  FormGroupPlugin,
  FormSelectPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  FormCheckboxPlugin,
  FormRadioPlugin,
  InputGroupPlugin,
  ListGroupPlugin,
  TablePlugin,
} from "bootstrap-vue";
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormRadioPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ListGroupPlugin);
Vue.use(TablePlugin);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
// https://mengxiong10.github.io/vue2-datepicker/index.html
// https://github.com/mengxiong10/vue2-datepicker
import { TheMask } from "vue-the-mask";
import MaskedInput from "vue-text-mask";

// Comentar isso na produção
// import mockJson from "./matricula.json";

import Loading from "@/components/loading.vue";

// Import Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { required, requiredIf, email, numeric } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ü' ]*$/);
const alphaNum = helpers.regex("alphaNum", /^[a-zA-Z0-9À-ÿ'.,\s-]*$/);
// const rgValid = helpers.regex("alphaNum", /^\d{1,2}\.?\d{3}\.?\d{3}-?\d{1,2}$/);
// const rneValid = helpers.regex("alphaNum", /^[A-Za-z0-9]{9}$/);

import { cpf } from "cpf-cnpj-validator";

const cpfValid = (value) => !helpers.req(value) || (value ? cpf.isValid(value) : false);
const cpfSize = (value) => !helpers.req(value) || (value ? helpers.len(value) == 11 : false);

const cepSize = (value) => !helpers.req(value) || (value ? helpers.len(value) == 8 : false);
const celularSize = (value) => !helpers.req(value) || (value ? helpers.len(value) == 11 : false);
const termsAccepted = (value) => value == true;

export default {
  name: "MatriculaCpv",
  metaInfo: { title: "Matrícula" },
  components: { BreadCpv, JumbotronCourse, DatePicker, TheMask, MaskedInput, Loading },
  props: {
    slug: {
      type: String,
      default: null,
    },
    tabelaPrecosId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tabelapreco: null,
      submitStatus: null,
      submiting: false,

      resp_options: [
        { text: "Aluno", value: "aluno" },
        { text: "Pai", value: "pai" },
        { text: "Mãe", value: "mae" },
        { text: "Outro", value: "outro" },
      ],
      preenchido_por_options: [
        { text: "Aluno", value: "aluno" },
        { text: "Pai", value: "pai" },
        { text: "Mãe", value: "mae" },
        { text: "Responsável", value: "responsavel" },
        { text: "Outro", value: "outro" },
      ],
      sel_unidade_id: null,
      faculdades: [],
      carreiras: [],
      origins: [],
      reasons: [],

      // Variáveis para o date-picker
      openDatePickerAluno: false,
      openDatePickerPai: false,
      openDatePickerMae: false,
      openDatePickerResponsavel: false,
      dateMask: [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
      // Fim Variáveis para o date-picker

      matricula: {
        gerais: {
          unidade_id: null,
          curso_id: null,
          level_id: null,
          turma_id: null,
          turma_oferta_id: null,
          promo_code_code: null,
          termos_aceitos: null,
          preenchido_por: null,
          preenchido_por_outro: null,
        },
        questions: [],
        faculdades: [null],
        carreiras: [null],
        origins: [],
        reasons: [],
        aluno: {
          cpf: null,
          rg: null,
          estrangeiro: false,
          rne: null,
          celular: null,
          nome: null,
          nascimento: null,
          genero: null,
          email: null,
          escola: null,
          cursinho: null,
          concluiu_ensino_medio: null,
          cep: null,
          endereco: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
        },
        pai: {
          nao_informar: 0,
          cpf: null,
          rg: null,
          estrangeiro: false,
          rne: null,
          celular: null,
          nome: null,
          nascimento: null,
          genero: null,
          email: null,
          profissao: null,
          empresa: null,
          cargo: null,
          cep: null,
          endereco: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
        },
        mae: {
          nao_informar: 0,
          cpf: null,
          rg: null,
          estrangeiro: false,
          rne: null,
          celular: null,
          nome: null,
          nascimento: null,
          genero: null,
          profissao: null,
          empresa: null,
          cargo: null,
          email: null,
          cep: null,
          endereco: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
        },
        responsavel: {
          responsavel: null,
          outro_responsavel: null,
          cpf: null,
          rg: null,
          estrangeiro: false,
          rne: null,
          celular: null,
          nome: null,
          nascimento: null,
          genero: null,
          profissao: null,
          empresa: null,
          cargo: null,
          email: null,
          cep: null,
          endereco: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
        },
      },

      opt_parentesco: [
        { value: "Pai", nome: "Pai" },
        { value: "Mãe", nome: "Mãe" },
        { value: "aluno", nome: "Aluno" },
      ],
      grades: [],

      genders: [],
      opt_genero: [],
      otherGenreId: null,
      isLoadingCepAluno: false,
      isLoadingCepPai: false,
      isLoadingCepMae: false,
      isLoadingCepResp: false,
    };
  },
  computed: {
    optUnidades: function () {
      let optUnidades = [];
      if (this.tabelapreco) {
        optUnidades = _.uniqBy(this.tabelapreco.ofertas, "unidade_id").map((oferta) => ({
          unidade_id: oferta.unidade_id,
          unidade_nome: oferta.unidade_nome,
        }));
      }
      return _.sortBy(optUnidades, "unidade_nome");
    },
    filteredOfertas: function () {
      return this.tabelapreco.ofertas.filter((row) => row.unidade_id == this.matricula.gerais.unidade_id);
    },
    filteredFaculdades: function () {
      let result = [];
      result = _.differenceBy(this.faculdades, this.matricula.faculdades, "id");

      return result;
    },
    filteredCarreiras: function () {
      let result = [];
      result = _.differenceBy(this.carreiras, this.matricula.carreiras, "id");

      return result;
    },
  },
  validations: {
    matricula: {
      gerais: {
        unidade_id: { required },
        // turma_oferta_id: { required },
        termos_aceitos: { termsAccepted },
        preenchido_por: { required },
        preenchido_por_outro: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.preenchido_por == "outro";
          }),
          alpha,
        },
      },
      questions: {
        $each: {
          value: {
            required: requiredIf(function (nestedModel) {
              return nestedModel.required == 1;
            }),
          },
        },
      },
      faculdades: {
        $each: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.length == 1;
          }),
        },
      },
      carreiras: {
        $each: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.length == 1;
          }),
        },
      },
      origins: { required },
      reasons: { required },
      aluno: {
        cpf: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.estrangeiro == false;
          }),
          numeric,
          cpfSize,
          cpfValid,
        },
        rg: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.estrangeiro == false;
          }),
        },
        rne: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.estrangeiro == true;
          }),
          // rneValid,
        },
        celular: {
          required,
          numeric,
          celularSize,
        },
        nome: { required, alphaNum },
        nascimento: { required },
        genero: { required },
        email: { required, email },
        escola: { required, alphaNum },
        // concluiu_ensino_medio: { required },
        cursinho: { alphaNum },
        cep: {
          required,
          numeric,
          cepSize,
        },
        endereco: { required, alphaNum },
        numero: { required, alphaNum },
        complemento: { alphaNum },
        bairro: { required, alphaNum },
        cidade: { required, alphaNum },
        estado: { required, alpha },
      },
      pai: {
        cpf: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false && nestedModel.estrangeiro == false;
          }),
          numeric,
          cpfSize,
          cpfValid,
        },
        rg: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false && nestedModel.estrangeiro == false;
          }),
        },
        rne: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false && nestedModel.estrangeiro == true;
          }),
          // rneValid,
        },
        celular: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          numeric,
          celularSize,
        },
        nome: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        nascimento: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
        },
        genero: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
        },
        email: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          email,
        },
        profissao: { alpha },
        empresa: { alpha },
        cargo: { alpha },
        cep: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          numeric,
          cepSize,
        },
        endereco: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        numero: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        complemento: { alphaNum },
        bairro: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        cidade: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        estado: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alpha,
        },
      },
      mae: {
        cpf: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false && nestedModel.estrangeiro == false;
          }),
          numeric,
          cpfSize,
          cpfValid,
        },
        rg: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false && nestedModel.estrangeiro == false;
          }),
        },

        rne: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false && nestedModel.estrangeiro == true;
          }),
          // rneValid,
        },
        celular: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          numeric,
          celularSize,
        },
        nome: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        nascimento: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
        },
        genero: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
        },
        profissao: { alpha },
        empresa: { alpha },
        cargo: { alpha },
        email: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          email,
        },
        cep: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          numeric,
          cepSize,
        },
        endereco: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        numero: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        complemento: { alphaNum },
        bairro: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        cidade: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alphaNum,
        },
        estado: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.nao_informar == false;
          }),
          alpha,
        },
      },
      responsavel: {
        responsavel: { required },
        outro_responsavel: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alpha,
        },
        cpf: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro" && nestedModel.estrangeiro == false;
          }),
          numeric,
          cpfSize,
          cpfValid,
        },
        rg: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro" && nestedModel.estrangeiro == false;
          }),
        },

        rne: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.responsavel === "outro" && nestedModel.estrangeiro == true;
          }),
          // rneValid,
        },
        celular: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          numeric,
          celularSize,
        },
        nome: {
          required: requiredIf((nestedModel, vm) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        nascimento: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
        },
        genero: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
        },
        profissao: { alpha },
        empresa: { alpha },
        cargo: { alpha },
        email: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          email,
        },
        cep: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          numeric,
          cepSize,
        },
        endereco: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        numero: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        complemento: { alphaNum },
        bairro: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        cidade: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        estado: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alpha,
        },
      },
    },
  },
  created: function () {
    //
  },
  mounted: function () {
    this.loadRecaptchaScript();
    this.getStudentAdmissionInitialData();
    this.getCourse();
    // if (process.env.NODE_ENV === "development") {
    //   this.matricula = mockJson;
    // }
  },
  beforeDestroy: function () {
    //
  },
  watch: {
    "matricula.pai.nao_informar": function (newVal, oldVal) {
      let indexRespOptions = this.resp_options.findIndex((item) => item.value == "pai");
      let indexPreenchidoPorOptions = this.preenchido_por_options.findIndex((item) => item.value == "pai");
      if (Number(newVal) == 0) {
        if (indexRespOptions == -1) {
          this.resp_options.push({ text: "Pai", value: "pai" });
        }
        if (indexPreenchidoPorOptions == -1) {
          this.preenchido_por_options.push({ text: "Pai", value: "pai" });
        }
      } else {
        if (indexRespOptions > -1) {
          this.resp_options.splice(indexRespOptions, 1);
        }
        if (indexPreenchidoPorOptions > -1) {
          this.preenchido_por_options.splice(indexPreenchidoPorOptions, 1);
        }
      }
      this.matricula.responsavel.responsavel = null;
      this.matricula.gerais.preenchido_por = null;
    },
    "matricula.mae.nao_informar": function (newVal, oldVal) {
      let indexRespOptions = this.resp_options.findIndex((item) => item.value == "mae");
      let indexPreenchidoPorOptions = this.preenchido_por_options.findIndex((item) => item.value == "mae");
      if (Number(newVal) == 0) {
        if (indexRespOptions == -1) {
          this.resp_options.push({ text: "Mãe", value: "mae" });
        }
        if (indexPreenchidoPorOptions == -1) {
          this.preenchido_por_options.push({ text: "Mãe", value: "mae" });
        }
      } else {
        if (indexRespOptions > -1) {
          this.resp_options.splice(indexRespOptions, 1);
        }
        if (indexPreenchidoPorOptions > -1) {
          this.preenchido_por_options.splice(indexPreenchidoPorOptions, 1);
        }
      }
      this.matricula.responsavel.responsavel = null;
      this.matricula.gerais.preenchido_por = null;
    },
  },
  methods: {
    getStudentAdmissionInitialData: function () {
      axios.get("sitecpv/cursinho/getStudentEnrollmentInitialData").then((response) => {
        if (response.status == 200) {
          this.faculdades = response.data.faculdades;
          this.carreiras = response.data.carreiras;
          this.origins = response.data.origins;
          this.reasons = response.data.reasons;

          this.opt_genero = response.data.genders;
          const otherGenre = this.opt_genero.find((gen) => {
            return Number(gen.other_gender) === 1;
          });
          if (otherGenre) {
            this.otherGenreId = otherGenre.id;
          }
        } else {
          this.$snotify.error("Ocorreu um erro");
        }
      });
    },
    getCourse() {
      axios
        .get(`sitecpv/cursinho/proximasturmas/getCourseDetail/${this.tabelaPrecosId}/${this.slug}`)
        .then((response) => {
          if (response) {
            this.tabelapreco = response.data.tabelapreco;

            const initialAnswers = [];
            this.tabelapreco.grupo_curso.enrollment_questions.forEach((question) => {
              // Inicializa com uma string vazia para texto ou um array vazio para múltipla escolha
              // if (question.question_with_alternatives && question.multiple_alternatives) {
              //   initialAnswers[question.id] = [];
              // } else {
              //   initialAnswers[question.id] = "";
              // }
              if (question.question_with_alternatives && question.multiple_alternatives) {
                initialAnswers.push({
                  id: question.id,
                  statement: question.statement,
                  alternatives: question.alternatives,
                  required: question.required,
                  value: [],
                });
              } else {
                initialAnswers.push({
                  id: question.id,
                  statement: question.statement,
                  alternatives: question.alternatives,
                  required: question.required,
                  value: "",
                });
              }
            });
            this.matricula.questions = initialAnswers;
          }
        });
    },
    loadRecaptchaScript() {
      if (!document.getElementById("recaptcha-script")) {
        const script = document.createElement("script");
        script.id = "recaptcha-script";
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lc7qt4UAAAAAAi9vH50o9GGdfVInkBtd5jU-u9B";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }
    },
    handleSubmit(e) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submit();
          this.submitStatus = "OK";
        }, 500);
      }
    },
    submit: async function () {
      this.submiting = true;
      const unidade = _.find(this.optUnidades, (o) => {
        return o.unidade_id === this.matricula.gerais.unidade_id;
      });

      // let visitorId = null;
      // if (!$cookies.isKey("visitor")) {
      //   visitorId = $cookies.get("visitor").id;
      // }

      // Tempo de preenchimento da matrícula
      // Mensagem push no navegador. Notificatio

      try {
        // Obtém o token do reCAPTCHA
        const token = await grecaptcha.execute("6Lc7qt4UAAAAAAi9vH50o9GGdfVInkBtd5jU-u9B", { action: "submit" });

        await axios
          .post("sitecpv/cursinho/postCursinhoStudentEnrollment", {
            matricula: this.matricula,
            tabelaPrecosId: this.tabelaPrecosId,
            unidade_nome: unidade.unidade_nome,
            recaptchaToken: token,
          })
          .then((response) => {
            if (response.status == 200) {
              // if (process.env.NODE_ENV === "production") {
              this.$router.push({ name: "detalhe-do-curso", params: { slug: this.slug } });
              this.resetFields();
              // }
              this.$snotify.success("Matrícula enviada com sucesso");
            } else {
              this.$snotify.error("Ocorreu um erro");
            }
          })
          .finally(() => {
            this.submiting = false;
          });
      } catch (error) {
        this.submiting = false;
        console.error("Erro ao validar reCAPTCHA", error);
        this.$snotify.error("Erro ao enviar o formulário.");
      }
    },
    resetFields() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    validateTextAlpha(text) {
      // Percorre cada caractere da string para encontrar o primeiro que não corresponde ao regex.
      const regex = /^[a-zA-ZÀ-ü' ]*$/;
      let retText = "";
      if (text) {
        for (let i = 0; i < text.length; i++) {
          if (!regex.test(text[i])) {
            retText = retText + `Caractere inválido: '${text[i]}' na posição ${i}<br/>`;
          }
        }
      }

      return retText;
    },
    validateTextAlphaNum(text) {
      // Percorre cada caractere da string para encontrar o primeiro que não corresponde ao regex.
      const regex = /^[a-zA-Z0-9À-ü'\s\.,]*$/;
      let retText = "";
      if (text) {
        for (let i = 0; i < text.length; i++) {
          if (!regex.test(text[i])) {
            retText = retText + `Caractere inválido: '${text[i]}' na posição ${i}<br/>`;
          }
        }
      }

      return retText;
    },
    buscaCepAluno: async function () {
      if (this.matricula.aluno.cep) {
        if (this.matricula.aluno.cep.length === 8) {
          this.isLoadingCepAluno = true;
          await fetch("https://viacep.com.br/ws/" + this.matricula.aluno.cep + "/json/").then((response) => {
            response.json().then((result) => {
              if (!result.erro) {
                this.matricula.aluno.endereco = result.logradouro;
                this.matricula.aluno.bairro = result.bairro;
                this.matricula.aluno.cidade = result.localidade;
                this.matricula.aluno.estado = result.uf;
              } else {
                this.matricula.aluno.endereco = null;
                this.matricula.aluno.bairro = null;
                this.matricula.aluno.cidade = null;
                this.matricula.aluno.estado = null;
              }
              this.isLoadingCepAluno = false;
            });
          });
        }
      }
    },
    buscaCepPai: async function () {
      if (this.matricula.pai.cep) {
        if (this.matricula.pai.cep.length === 8) {
          await fetch("https://viacep.com.br/ws/" + this.matricula.pai.cep + "/json/").then((response) => {
            response.json().then((result) => {
              if (!result.erro) {
                this.matricula.pai.endereco = result.logradouro;
                this.matricula.pai.bairro = result.bairro;
                this.matricula.pai.cidade = result.localidade;
                this.matricula.pai.estado = result.uf;
              } else {
                this.matricula.pai.endereco = null;
                this.matricula.pai.bairro = null;
                this.matricula.pai.cidade = null;
                this.matricula.pai.estado = null;
              }
            });
          });
        }
      }
    },
    buscaCepMae: async function () {
      if (this.matricula.mae.cep) {
        if (this.matricula.mae.cep.length === 8) {
          await fetch("https://viacep.com.br/ws/" + this.matricula.mae.cep + "/json/").then((response) => {
            response.json().then((result) => {
              if (!result.erro) {
                this.matricula.mae.endereco = result.logradouro;
                this.matricula.mae.bairro = result.bairro;
                this.matricula.mae.cidade = result.localidade;
                this.matricula.mae.estado = result.uf;
              } else {
                this.matricula.mae.endereco = null;
                this.matricula.mae.bairro = null;
                this.matricula.mae.cidade = null;
                this.matricula.mae.estado = null;
              }
            });
          });
        }
      }
    },
    buscaCepResponsavel: async function () {
      if (this.matricula.responsavel.cep) {
        if (this.matricula.responsavel.cep.length === 8) {
          await fetch("https://viacep.com.br/ws/" + this.matricula.responsavel.cep + "/json/").then((response) => {
            response.json().then((result) => {
              if (!result.erro) {
                this.matricula.responsavel.endereco = result.logradouro;
                this.matricula.responsavel.bairro = result.bairro;
                this.matricula.responsavel.cidade = result.localidade;
                this.matricula.responsavel.estado = result.uf;
              } else {
                this.matricula.responsavel.endereco = null;
                this.matricula.responsavel.bairro = null;
                this.matricula.responsavel.cidade = null;
                this.matricula.responsavel.estado = null;
              }
            });
          });
        }
      }
    },
    copiaAlunoPai: function () {
      this.matricula.pai.cep = this.matricula.aluno.cep;
      this.matricula.pai.endereco = this.matricula.aluno.endereco;
      this.matricula.pai.numero = this.matricula.aluno.numero;
      this.matricula.pai.complemento = this.matricula.aluno.complemento;
      this.matricula.pai.bairro = this.matricula.aluno.bairro;
      this.matricula.pai.cidade = this.matricula.aluno.cidade;
      this.matricula.pai.estado = this.matricula.aluno.estado;
    },
    copiaAlunoMae: function () {
      this.matricula.mae.cep = this.matricula.aluno.cep;
      this.matricula.mae.endereco = this.matricula.aluno.endereco;
      this.matricula.mae.numero = this.matricula.aluno.numero;
      this.matricula.mae.complemento = this.matricula.aluno.complemento;
      this.matricula.mae.bairro = this.matricula.aluno.bairro;
      this.matricula.mae.cidade = this.matricula.aluno.cidade;
      this.matricula.mae.estado = this.matricula.aluno.estado;
    },
    copiaAlunoResponsavel: function () {
      this.matricula.responsavel.cep = this.matricula.aluno.cep;
      this.matricula.responsavel.endereco = this.matricula.aluno.endereco;
      this.matricula.responsavel.numero = this.matricula.aluno.numero;
      this.matricula.responsavel.complemento = this.matricula.aluno.complemento;
      this.matricula.responsavel.bairro = this.matricula.aluno.bairro;
      this.matricula.responsavel.cidade = this.matricula.aluno.cidade;
      this.matricula.responsavel.estado = this.matricula.aluno.estado;
    },
    selFaculdade: function () {},
    inputFaculdade: function (index) {
      if (this.matricula.faculdades[index] == null) {
        this.matricula.faculdades.splice(index, 1);
        if (this.matricula.faculdades[this.matricula.faculdades.length - 1] !== null) {
          this.matricula.faculdades.push(null);
        }
      } else {
        if (this.filteredFaculdades.length > 0) {
          if (this.matricula.faculdades[this.matricula.faculdades.length - 1] !== null) {
            this.matricula.faculdades.push(null);
          }
        }
      }
    },
    inputCarreira: function (index) {
      if (this.matricula.carreiras[index] == null) {
        this.matricula.carreiras.splice(index, 1);
        if (this.matricula.carreiras[this.matricula.carreiras.length - 1] !== null) {
          this.matricula.carreiras.push(null);
        }
      } else {
        if (this.filteredCarreiras.length > 0) {
          this.matricula.carreiras.push(null);
        }
      }
    },
    handleChangeAluno(evt) {
      this.matricula.aluno.nascimento = evt.target.value;
      this.openDatePickerAluno = false;
    },
    handleChangePai(evt) {
      this.matricula.pai.nascimento = evt.target.value;
      this.openDatePickerPai = false;
    },
    handleChangeMae(evt) {
      this.matricula.mae.nascimento = evt.target.value;
      this.openDatePickerMae = false;
    },
    handleChangeResponsavel(evt) {
      this.matricula.responsavel.nascimento = evt.target.value;
      this.openDatePickerResponsavel = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.list-matricula {
  margin: 0px 20px;
  padding: 0px 0px 10px;
  width: auto;
  height: auto;
}
.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.main-admissao {
  margin: 104.5px 0px 0px;
  font-family: "Open Sans";
  letter-spacing: -0.004em;
  .title_page {
    margin: 0px 0px -10px;
    padding: 50px 0px 45px;
    font-family: "Montserrat";
    background-repeat: repeat;
    background-position: center;
    background-color: #f16f31;
    background-image: url("@/components/global/image/cpv-fundo-4.png");
    border-radius: 0px;

    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}
.divider {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.divider::before,
.divider::after {
  flex: 1;
  content: "";
  padding: 1px;
  background-color: rgba(0, 0, 0, 0.125);
  margin: 35px 10px;
}
.float-btn {
  position: fixed;
  right: 40px;
  bottom: 20px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Cinza com 60% de opacidade */
  z-index: 10; /* Para garantir que o overlay fique acima do conteúdo */
}
</style>
