<template>
  <div>
    <component v-bind:is="layout"></component>
  </div>
</template>

<script>
import Vue from "vue";
import siteLayout from "./layouts/site-layout.vue";
import authLayout from "./layouts/auth-layout.vue";
import userLayout from "./layouts/user-layout.vue";
import { mapState } from "vuex";

export default {
  metaInfo: {
    title: "Cursinho CPV",
    titleTemplate: "%s - Cursinho CPV",
  },
  data() {
    return {};
  },
  components: {
    site: siteLayout,
    auth: authLayout,
    user: userLayout,
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
    ...mapState({
      bodyClass: (state) => state.bodyClass,
    }),
  },

  created: function () {},
  beforeDestroy: function () {
    this.$leaveChannel("main");
  },
  mounted() {},
  watch: {},
  methods: {
    //
  },
};
</script>
