<template>
  <section class="fade-in-up mb-5">
    <b-jumbotron class="header">
      <h1 class="text-center font-weight-bolder">Simulados Abertos</h1>
    </b-jumbotron>

    <BreadCursinho />

    <b-container fluid="sm">
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="6"
          lg="4"
          xl="4"
          v-for="(simulado, index) in sortedEvents"
          :key="index"
          class="my-2"
        >
          <b-card no-body class="card__simulados mx-auto">
            <b-link :href="getLink(simulado)" target="_blank" class="d-block my-auto mx-auto link__simulado">
              <div class="img__simulado">
                <b-img
                  :src="simulado.image"
                  :alt="simulado.name"
                  sizes="(max-width: 200px) 100vw, 270px"
                  width="225px"
                  height="123px"
                />
                <div class="overlay__content">
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
              <div class="header__simulados border-bottom border-light">
                <h4 class="text-center font-weight-light my-2">
                  {{ simulado.date && simulado.hora.length && simulado.local ? "Inscreva-se" : "Receba o alerta!" }}
                </h4>
              </div>
              <p :class="simulado.name != '' ? 'h6 text-center text-dark font-weight-bolder my-3' : ''">
                {{ simulado.name }}
              </p>
            </b-link>
            <div class="my-3simulados">
              <div class="my-2 content">
                <i class="fa-light fa-calendar mx-2"></i>
                <span>{{ simulado.weekday ? `${simulado.weekday}${simulado.date ? "," : ""}` : "" }}</span>
                <span class="ml-1">{{ simulado.date || "A definir" }}</span>
              </div>
              <div class="my-2 content" v-for="(hora, idx) in simulado.hora" :key="idx">
                <div v-if="hora.horario.length">
                  <i :class="hora.icon" class="mx-2"></i>
                  <span>{{ hora.horario || "A definir" }}</span>
                </div>
              </div>
              <div class="my-2 simulado__local content">
                <i class="fa-light fa-location-dot mx-2"></i>
                <span v-if="simulado.local == ''">A definir</span>
                <span v-else>{{ simulado.local || "A definir" }}</span>
              </div>
            </div>
            <div class="line__divisory">
              <div class="first__divisory"></div>
              <div class="second__divisory"></div>
            </div>
          </b-card>

          <!-- <div v-for="hora in simulado.hora" :key="hora">
            <div v-if="hora.horario.length">
              <i :class="hora.icon"></i>
              <span>{{ hora.horario }}</span>
            </div>

          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import BreadCursinho from "@/components/global/bread-cpv";
export default {
  name: "PageSimuladosAbertos",
  components: { BreadCursinho },
  data() {
    return {
      simulados: [
        {
          image: require("@/assets/image/simulados_abertos/einstein.png"),
          name: "",
          date: "",
          hora: [
            {
              horario: "",
              icon: "fa-light fa-clock",
            },
          ],
          local: "",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-einstein-marco-2025",
        },
        // {
        //   image: require("@/assets/image/simulados_abertos/enem.jpg"),
        //   name: "",
        //   date: "",
        //   hora: [
        //     {
        //       horario: "",
        //       icon: "fa-light fa-clock",
        //     },
        //     {
        //       horario: "",
        //       icon: "fa-light fa-clock",
        //     },
        //   ],
        //   local: "",
        //   sign_up: "https://dicas.cpv.com.br/simulado-aberto-enem-agosto-2024",
        // },
        {
          image: require("@/assets/image/simulados_abertos/simulado-espm-1.jpg"),
          name: "",
          date: "17/05/2025",
          hora: [
            {
              horario: "10h00 às 15h00",
              icon: "fa-light fa-clock",
            },
          ],
          local: "Vila Olímpia ou On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-espm-17-maio-2025",
        },
        {
          image: require("@/assets/image/simulados_abertos/fgv.png"),
          name: "",
          date: "05/04/2025",
          hora: [
            {
              horario: "08h00 às 18h00",
              icon: "fa-light fa-clock",
            },
          ],
          local: "Vila Olímpia ou On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-fgv-unificado-abril-2025",
        },
        {
          image: require("@/assets/image/simulados_abertos/fmabc.png"),
          name: "",
          date: "17/05/2025",
          hora: [
            {
              horario: "14h00 às 19h00",
              icon: "fa-light fa-clock",
            },
          ],
          local: "Vila Olímpia ou On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-fmabc-17-maio-2025",
        },
        {
          image: require("@/assets/image/simulados_abertos/fuvest.jpg"),
          name: "",
          date: "12/04/2025",
          hora: [
            {
              horario: "14h00 às 19h00",
              icon: "fa-light fa-clock",
            },
          ],
          local: "Vila Olímpia ou On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-fuvest-12-abril-2025",
        },
        // {
        //   image: require("@/assets/image/simulados_abertos/insper.jpg"),
        //   name: "",
        //   date: "29/03/2025",
        //   hora: [
        //     {
        //       horario: "07h30 ÀS 12h45",
        //       icon: "fa-light fa-clock",
        //     },
        //     {
        //       horario: "14h30 ÀS 19h45",
        //       icon: "fa-light fa-clock",
        //     },
        //   ],
        //   local: "On-line",
        //   sign_up: "https://dicas.cpv.com.br/simulado-aberto-insper-marco-2025",
        // },
        {
          image: require("@/assets/image/simulados_abertos/insper.jpg"),
          name: "",
          date: "10/05/2025",
          hora: [
            {
              horario: "07h30 ÀS 12h45",
              icon: "fa-light fa-clock",
            },
            {
              horario: "14h30 ÀS 19h45",
              icon: "fa-light fa-clock",
            },
          ],
          local: "On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-insper-10-maio-2025",
        },
        {
          image: require("@/assets/image/simulados_abertos/mackenzie_medicina.png"),
          name: "",
          date: "26/04/2025",
          hora: [
            {
              horario: "14h00 às 19h00",
              icon: "fa-light fa-clock",
            },
          ],
          local: "Vila Olímpia ou On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-mackenzie-med-26-abril-2025",
        },
        {
          image: require("@/assets/image/simulados_abertos/simulados-abertos-mack.jpg"),
          name: "",
          date: "10/05/2025",
          hora: [
            {
              horario: "14h00 às 19h00",
              icon: "fa-light fa-clock",
            },
          ],
          local: "Vila Olímpia ou On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-mackenzie-10-maio-2025",
        },
        {
          image: require("@/assets/image/simulados_abertos/simulados-abertos-puc.jpg"),
          name: "",
          date: "26/04/2025",
          hora: [
            {
              horario: "14h00 às 19h00",
              icon: "fa-light fa-clock",
            },
          ],
          local: "Vila Olímpia ou On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-puc-sp-26-abril-2025",
        },
        {
          image: require("@/assets/image/simulados_abertos/SAnta-casa3.png"),
          name: "",
          date: "",
          hora: [
            {
              horario: "",
              icon: "fa-light fa-clock",
            },
          ],
          local: "",
          sign_up: "",
        },

        {
          image: require("@/assets/image/simulados_abertos/simulados-abertos-unesp.jpg"),
          name: "",
          date: "10/05/2025",
          hora: [
            {
              horario: "14h00 às 19h00",
              icon: "fa-light fa-clock",
            },
          ],
          local: "Vila Olímpia ou On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-unesp-10-maio-2025",
        },

        {
          image: require("@/assets/image/simulados_abertos/simulados-abertos-unicamp.jpg"),
          name: "",
          date: "17/05/2025",
          hora: [
            {
              horario: "14h00 às 19h00",
              icon: "fa-light fa-clock",
            },
          ],
          local: "Vila Olímpia ou On-line",
          sign_up: "https://dicas.cpv.com.br/simulado-aberto-unicamp-17-maio-2025",
        },
      ],
    };
  },
  created: function () {
    //
  },
  beforeDestroy: function () {
    //
  },
  computed: {
    sortedEvents() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth(); // 0 - Janeiro, 11 - Dezembro
      const currentYear = currentDate.getFullYear();
      const weekdays = [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ];

      return this.simulados
        .map((event) => {
          const dateMatch = event.date.match(/(\d{2})\/(\d{2})\/(\d{4})/);

          if (dateMatch) {
            const [_, day, month, year] = dateMatch;
            const dateObject = new Date(year, month - 1, day);

            const weekday = weekdays[dateObject.getDay()];

            event.dateObject = dateObject;
            event.weekday = weekday;
            event.formattedDate = `${day}/${month}/${year}`;
          } else {
            // Handle invalid date format
            event.dateObject = null;
            event.weekday = "";
            event.formattedDate = event.date;
          }

          return event;
        })
        .sort((a, b) => {
          if (!a.dateObject) return 1; // Invalid dates move to the end
          if (!b.dateObject) return -1;

          const aIsPast = a.dateObject < currentDate;
          const bIsPast = b.dateObject < currentDate;

          if (aIsPast && !bIsPast) return 1;
          if (!aIsPast && bIsPast) return -1;

          return a.dateObject - b.dateObject;
        });
    },
  },
  methods: {
    getLink(eventos) {
      if (
        eventos.date == "" ||
        eventos.date == "A definir" ||
        (eventos.date == "A Definir" && eventos.hora == "") ||
        eventos.hora == "A definir" ||
        (eventos.hora == "A Definir" && eventos.local == "") ||
        eventos.local == "A definir" ||
        eventos.local == "A Definir"
      ) {
        return "https://dicas.cpv.com.br/interesse-em-simulados";
      } else if (eventos.sign_up) {
        return eventos.sign_up;
      } else {
        return "#";
      }
    },
  },
};
</script>

<style scoped>
.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.header {
  margin-top: -8px;
  background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
  background-repeat: repeat;
  background-position: center;
  object-fit: cover;
  background-color: hsl(355, 84%, 61%);
  border-radius: 0;
}

.header > h1 {
  font-size: 51px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: -1.25px;
  line-height: 70.635px;
}

.card__simulados {
  width: 270px;
  border: none;
}

.card__simulados .link__simulado {
  text-decoration: none;
}

.card__simulados .img__simulado {
  position: relative;
  width: 225px;
  height: auto;
}

.card__simulados .img__simulado::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, 0.32);
  opacity: 0;
  border-radius: 8px;
  transition: opacity 0.3s;
}

.card__simulados .overlay__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: hsl(0, 0%, 92%);
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  border: 2px solid hsl(0, 0%, 92%);
  border-radius: 50%;
  padding: 2px 9px;
}

.card__simulados .img__simulado:hover::before,
.card__simulados .img__simulado:hover .overlay__content {
  opacity: 1;
}

.link__simulado .header__simulados h4 {
  color: hsl(268, 44%, 41%);
}

.link__simulado .header__simulados h4:hover {
  color: hsl(273, 50%, 22%);
}

.simulados i {
  color: hsl(354, 84%, 61%);
  font-size: 18px;
}

.simulados .simulado__local {
  padding-left: 0.15rem;
}

.simulados span {
  font-size: 15px;
  color: hsl(273, 50%, 22%);
}

.simulados .content i,
.simulados .content span {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.line__divisory {
  margin: 10px 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.line__divisory .first__divisory {
  border: 1px solid hsl(354, 84%, 61%);
  width: 10%;
}

.line__divisory .second__divisory {
  border: 1px solid #dcdcdc;
  width: 85%;
}
</style>
