<template>
  <div class="course_detalhe">
    <div v-if="isLoading" style="height: 60vh" class="d-flex align-items-center">
      <loading message="Carregando Curso"></loading>
    </div>
    <div v-else class="main_course fade-in-up mb-5">
      <jumbotron-course :title="curso.nome_site" class="jumbotron_course" />
      <bread-cpv :title="curso.nome_site" :slug="slug" />
      <div class="content_course">
        <b-container fluid="lg">
          <!-- <b-col cols="12" class="d-block d-md-block d-lg-none">
            <topbarMatricula :curso="curso" :slug="slug" />
          </b-col> -->
          <b-row>
            <b-col cols="12" md="12" lg="8" class="column_content-course">
              <div class="sidebar_position_right">
                <!-- <info-header-course :title="curso.nome_site" :classes="curso.unidades" :date="curso.data_de_inicio" />
                <line-divisory /> -->
                <component class="mt-2" v-if="cursoComponent" :is="cursoComponent" />
                <!-- <curso-component></curso-component> -->
              </div>
            </b-col>
            <b-col cols="12" md="4" class="d-none d-md-none d-lg-block">
              <sidebarMatricula class="right" :curso="curso" :slug="slug" />
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- :titulo="curso.nome_site"
      icone="fa-regular fa-clock-seven"
      :hora="curso.short_horario"
      :unidades="curso.unidades"
      cifrao="R$"
      :valorPresencial="curso.valor_total_presencial"
      :valorOnline="curso.valor_total_ead"
      iconMaps="fa-sharp fa-regular fa-location-dot"
      :tabela_precos_id="curso.tabela_precos_id"
      :oferta_presencial="curso.oferta_presencial.valor_total_presencial" -->
      <panel-matricula :curso="curso" :slug="slug" />
      <WhatsButton :isPanelVisible="isPanelVisible" />
    </div>
  </div>
</template>

<script>
import WhatsButton from "@/components/global/whatsapp-button.vue";
import JumbotronCourse from "@/components/global/components_cursos/jumbotron_courses";
import BreadCpv from "@/components/global/bread-cpv.vue";
import InfoHeaderCourse from "@/components/global/components_cursos/more_info.vue";
import LineDivisory from "@/components/global/components_cursos/divisory";
import PanelMatricula from "./panel";
import SidebarMatricula from "./sidebar_matricula";
// import TopbarMatricula from "./topbar_matricula";
import Loading from "@/components/loading.vue";

export default {
  name: "CursoDetalhe",
  props: {
    slug: {
      type: String,
      default: null,
    },
  },
  components: {
    Loading,
    JumbotronCourse,
    // InfoHeaderCourse,
    LineDivisory,
    PanelMatricula,
    SidebarMatricula,
    BreadCpv,
    WhatsButton,
    // cursoComponent,
    // TopbarMatricula
  },
  data() {
    return {
      isLoading: false,
      curso: null,
      cursoComponent: null,
      isPanelVisible: false,
    };
  },
  computed: {
    matriculaLink() {
      return {
        name: "CursoDetalhe",
        params: {
          turma: this.curso.turma,
          slug: this.curso.slug,
        },
      };
    },
  },
  created() {
    this.getCourse();
  },
  beforeDestroy: function () {
    //
  },
  methods: {
    getCourse: async function () {
      this.isLoading = true;
      try {
        await axios.get(`sitecpv/cursinho/proximasturmas/getCourse/${this.slug}`).then((response) => {
          this.curso = response.data.course;
        });
        if (this.curso.vue_component) {
          const componentPath = this.curso.vue_component;
          const module = await this.loadComponent(componentPath);
          this.cursoComponent = module.default;
        }

        this.isLoading = false;
      } catch (error) {
        console.error("Erro ao carregar o componente:", error);
      }
    },
    loadComponent: async function (componentPath) {
      try {
        const module = await import(`${componentPath}`);
        return module;
      } catch (error) {
        console.error(`Erro ao carregar o componente: ${componentPath}`, error);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.course_detalhe {
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-size: 16px !important;
}
.jumbotron_course {
  border-radius: 0;
  background-color: rgb(259, 72, 87);
  background-image: url("");
}
</style>
